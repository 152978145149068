import * as React from 'react';

import {
    useDialogs,
    useLoading,
    useTranslation,
    usePaginator,
    useSearchPaginate,
    useDataTable,
    ProgressBar,
    useSearch,
    useSearchFilters,
    Portal,
    Tag,
    G,
    useForm,
} from '@components';
import { ICommunication, ISecurity, IWorkspace } from '@models';

import './CommunicationsList.scss';
import { searchExportToXls } from '@store/actions/search';
import { downloadBlob } from '@utils/file-utils';
import dateUtils from '@utils/date-utils';
import i18next from 'i18next';

export interface IProps {
    footer?: any;
    onChange?: Function;
    onSubmit: Function;
    workSpaces: IWorkspace[];
    workId: number;
    saveCommunication: Function;
    getCommunication: Function;
    getDocuments: Function;
    getDepartmentWorkers: Function;
    security: ISecurity;
}

type CommunicationRow = ICommunication & {
    receiverNames: string;
    total: number;
    validated: number;
    createdBy: number;
    communicationStatus: boolean;

    //Indica el departamento al que pertenece el usuario que creo la comunicacion
    departmentIds: number[];
    createdByName: string;
}

function ViewCommunication({ id, workId, row, getCommunication }: {
    id: number,
    workId: number,
    row: CommunicationRow,
    getCommunication: Function,
}) {
    const { t } = useTranslation();
    const loading = useLoading(true);
    const [communication, setCommunication] = React.useState<ICommunication | undefined>();

    const initialize = loading.wrap(async () => {
        const c = await getCommunication(workId, id);
        setCommunication(c);
    });

    React.useEffect(() => {
        initialize();
    }, []);

    const documents = communication?.documents ?? [];

    return <div className='c CommunicationsList'>
        {loading.renderBox()}
        <div className='r'>
            <div className='view' dangerouslySetInnerHTML={{ __html: row.description }}></div>
            <div className='c mg'>
                <span className='title'>{t('communications.files')}</span>
                {documents.map(d => <div className='file-row' key={d.id}>
                    <span>{d.name}</span>
                    <a href={`/api/files/${workId}/document/${d.id}`}>
                        <i className='fa fa-download' />
                    </a>
                </div>)}
            </div>
        </div>
    </div>
}

function EditCommunication({ id, workId, row, saveCommunication, getCommunication, clearDialog, reload }: {
    id: number,
    workId: number,
    row: CommunicationRow,
    getCommunication: Function,
    saveCommunication: Function,
    clearDialog: Function,
    reload: Function,
}) {
    const { t } = useTranslation();
    const loading = useLoading();
    const [communication, setCommunication] = React.useState<ICommunication>();

    const form = useForm<Partial<ICommunication>>({
        initialValues: {
            toDate: row.toDate
        }
    });

    const initialize = loading.wrap(async () => {
        const c = await getCommunication(workId, id);
        setCommunication(c);
    });

    React.useEffect(() => {
        initialize();
    }, []);

    const doSave = loading.wrap(async () => {
        const comm: ICommunication = {
            departments: communication!.departments,
            description: communication!.description,
            isRecursive: communication!.isRecursive,
            fromDate: communication!.fromDate,
            toDate: form.values.toDate,
            file: communication!.file,
            id: communication!.id,
            title: communication!.title,
            jobs: communication!.jobs,
            workId: communication!.workId,
            isActive: communication!.isActive,
            targetType: communication!.targetType,
            workspaces: communication!.workspaces,
        }


        const res = await saveCommunication(comm);
        if (res.hasValue) {
            clearDialog();
            reload();
        }
    });

    const checkDate = (value: Date) => {
        if (value < row.fromDate) {
            return <small className="p-error">{t('communications.endDate.error')}</small>
        }
    }

    return <div className='c'>
        {loading.renderBox()}
        <div className='communication-form'>
            <div className='form-3 l200 sm pd'>
                <G label={t('End date')}>
                    {form.input('toDate', { type: 'date' })}
                </G>
                {checkDate(form.values.toDate)}
            </div>
        </div>
        <button disabled={row.fromDate > form.values.toDate} onClick={() => doSave()} className='success'>{t('Save')}</button>
    </div>
}

function RemoveCommunication({ row, deleteCommunication, clearDialog, reload }: {
    row: CommunicationRow,
    deleteCommunication: Function,
    clearDialog: Function,
    reload: Function,
}) {
    const { t } = useTranslation();
    const loading = useLoading();

    const remove = loading.wrap(async () => {
        await deleteCommunication(row);
        clearDialog();
        reload();
    });

    return <div className='c'>
        {loading.renderBox()}
        <div className='form-1 l200 sm pd'>
            <p style={{ fontWeight: "bold" }}>{t('communication.remove.confirmation')}</p>
        </div>
        <button className='danger' onClick={() => remove()}>{t('Delete')}</button>
    </div>
}


export function CommunicationsList(props: IProps) {
    const { t } = useTranslation();

    const [anyDeleted, setAnyDeleted] = React.useState(false);

    const loading = useLoading();

    const userFilters = useSearchFilters({
        workId: props.workId,
        security: props.security,
        name: 'communications/list',
        references: {
        },
        persist: true,
    })

    React.useEffect(() => {
        if(userFilters.filters?.active == null){
            userFilters.setFilter('active', true);
        }
    }, [userFilters.filters]);

    const data = useSearchPaginate<CommunicationRow>({
        search: props.security.isContractor() ? 'communications/list.contractors' : 'communications/list',
        filters: userFilters.merge({ workId: props.workId }),
        workId: props.workId,
        dependencies: [userFilters.filters],
        lazy: true,
    });

    const paginator = usePaginator<CommunicationRow>({
        data: data.value,
        onChange: (page, limit) =>
            data.doSearch(userFilters.merge({ workId: props.workId }), page, limit),
    });

    const dialogs = useDialogs();

    React.useEffect(() => {
        if (anyDeleted) {
            reload();
            setAnyDeleted(false);
        }
    }, [anyDeleted]);

    const reload = loading.wrap(async () => {
        await data.doSearch(userFilters.merge({ workId: props.workId }));
    });

    React.useEffect(() => {
        reload();
    }, [userFilters.filters]);

    const showCommunication = (row: CommunicationRow) => {
        dialogs.show('communication', row);
    }

    const checkArrays = (arr1: any[], arr2: any[]) => {
        for (var i = 0; i < arr1.length; i++) {
            for (var j = 0; j < arr2.length; j++) {
                if (arr1[i] == arr2[j]) {
                    return true;
                }
            }
        }
        return false;
    }

    const actions = () => {
        const actions = [
            {
                icon: 'fas fa-folder',
                title: t('communications.view.data'),
                onClick: showCommunication,
                disabled: (x: CommunicationRow) => false
            },
        ];
        if (props.security.user?.departments?.some(x => data.data.filter(d => d.departmentIds.includes(x))) || props.security.isGestor() || data.data.some(x => x.createdBy == props.security.user?.id)) {
            actions.push({
                icon: 'fas fa-edit',
                title: t('Edit end date'),
                onClick: (row: CommunicationRow) => {
                    dialogs.show('communication-edit', row);
                },
                disabled: (x: CommunicationRow) => {
                    if (props.security.isGestor()) {
                        return false;
                    } else {
                        if (x.createdBy == props.security.user?.id) {
                            return false;
                        }
                        else {
                            if (checkArrays(x.departmentIds, props.security.user?.departments || [])) {
                                return false;
                            }
                            else {
                                return true;
                            }
                        }
                    }
                }
            });
        }
        if (props.security.hasPolicy("communication.create") || props.security.isGestor()) {
            actions.push({
                icon: 'fas fa-file-excel',
                title: t('communications.export'),
                onClick: (row: CommunicationRow) => {
                    exportCommunicationToXls(row);
                },
                disabled: (x: CommunicationRow) => false
            });
        }
        if (!props.security.isContractor()) {
            actions.push({
                icon: 'fas fa-list',
                title: t('communications.receivers'),
                onClick: (row: CommunicationRow) => {
                    dialogs.show('communication-receivers', row);
                },
                disabled: (x: CommunicationRow) => false
            });
        }
        if (props.security.isGestor()) {
            actions.push({
                icon: 'fas fa-trash',
                title: t('communications.remove'),
                onClick: (x: CommunicationRow) => dialogs.show('communication-delete', x),
                disabled: (x: CommunicationRow) => x.isActive == false,
            });
        }
        return actions;
    };

    React.useEffect(() => {
        actions();
    }, [data.data]);

    const cleanHTML = (html: string) => {
        const regexp: RegExp = /( |<([^>]+)>)/ig;
        return html.replace(regexp, '');
    }

    const dataTable = useDataTable<CommunicationRow>({
        columns: !props.security.isContractor() ? [
            { title: t('UserName'), field: 'createdByName', className: 'center w-10' },
            { title: t('creation.date'), field: 'creationDate', className: 'center w-10', delegate: 'date' },
            { title: t('communication.fromDate'), field: 'fromDate', className: 'center w-10', delegate: 'date' },
            { title: t('communication.toDate'), field: 'toDate', className: 'center w-10', delegate: 'date' },
            {
                title: t('Title'), className: 'center',
                render: d =>
                    <div className='communication-title' title={cleanHTML(d.description)}>
                        {d.title}
                    </div>
            },
            {
                title: t('communications.validated'),
                render: d => {
                    const percentage = ((d.validated / d.total) * 100).toFixed(2);
                    return <div title={`${percentage}%`}>
                        <Tag value={`${d.validated}/${d.total}`} />
                    </div>
                },
                // <div title={`${d.validated}/${d.total}`}>
                //     <ProgressBar
                //         value={((d.validated / d.total) * 100).toFixed(2)} />
                // </div>,
                className: 'center w-5'
            },
            { title: t('Status'), render: d => !d.communicationStatus ? <Tag className={'resource-status-1'}>{t('Inactive')}</Tag> : <Tag className={'resource-status-2'}>{t('Active')}</Tag>, className: 'center w-5' },
        ] : [
            { title: t('creation.date'), field: 'creationDate', className: 'center w-10', delegate: 'date' },
            { title: t('communication.fromDate'), field: 'fromDate', className: 'center w-10', delegate: 'date' },
            { title: t('communication.toDate'), field: 'toDate', className: 'center w-10', delegate: 'date' },
            { title: t('Title'), field: 'title', className: 'center' },
            { title: t('Status'), render: d => !d.communicationStatus ? <Tag className={'resource-status-1'}>{t('Inactive')}</Tag> : <Tag className={'resource-status-2'}>{t('Active')}</Tag>, className: 'center w-5' },
        ],
        actions: [
            {
                actions: actions(),
            }
        ],
        data: data.data,
    });


    const deleteCommunication = async (row: CommunicationRow) => {
        const c = await props.getCommunication(props.workId, row?.id);
        const communication: ICommunication = {
            id: c.id,
            workId: c.workId,
            title: c.title,
            description: c.description,
            fromDate: c.fromDate,
            toDate: c.toDate,
            departments: c.departments,
            jobs: c.jobs,
            workspaces: c.workspaces,
            file: c.file,
            isRecursive: c.isRecursive,
            targetType: c.targetType,
        }
        const res = await props.saveCommunication(communication, true);
        if (res.isError === false) {
            setAnyDeleted(true);
        }
    };

    const renderReceivers = (row: CommunicationRow) => {
        return <RenderReceivers row={row} />
    }

    const renderEditCommunication = (row: CommunicationRow) => {
        return <EditCommunication row={row} id={row.id} getCommunication={props.getCommunication}
            workId={props.workId} saveCommunication={props.saveCommunication}
            clearDialog={dialogs.clear} reload={reload} />
    }

    const renderRemoveCommunication = (row: CommunicationRow) => {
        return <RemoveCommunication row={row} clearDialog={dialogs.clear} deleteCommunication={deleteCommunication} reload={reload} />
    }

    function RenderReceivers({ row }: { row: CommunicationRow }) {
        const searchForReceivers = useSearch({
            search: 'communications/receivers',
            workId: props.workId,
            filters: {
                communicationId: row.id,
            },
            lazy: true,
        });

        const cleanSearch = (search: any[]) => {
            const clean: any[] = [];
            search.map((s: any) => {
                if (clean.some(c => c.userId == s.userId) === false) {
                    clean.push(s);
                }
            })

            return clean;
        }
        React.useEffect(() => {
            searchForReceivers.doSearch({ communicationId: row.id });
        }, []);

        const renderValidated = (row: any): string => {
            switch (row.status) {
                case 'Validated':
                    return t('Validated')
                    break;
                default:
                    return t('Not validated')
                    break;
            }
        }

        const dataTable = useDataTable<any>({
            columns: [
                { title: t('name'), field: 'name', sortKey: 'name' },
                {
                    title: t('ResourceType'), render: d => {
                        switch (d.type) {
                            case 'Worker':
                                return t('communication.worker')
                                break;
                            default:
                                return t('communication.contractor')
                                break;
                        }
                    }, sortKey: 'type'
                },
                {
                    title: t('Status'), render: d => {
                        return renderValidated(d);
                    }, sortKey: 'status'
                },
                { title: t('Validation date'), render: d => dateUtils.formatDateTimeFromUtc(d.validationDate), sortKey: 'validationDate' },
            ],
            data: cleanSearch(searchForReceivers.value),
            onSort: (key, order) => searchForReceivers.doSearch({ communicationId: row.id, sort: key, order: order }),
        });

        return <div>{dataTable()}</div>
    };

    const renderCommunication = (row: CommunicationRow) => {
        return <ViewCommunication
            id={row.id}
            workId={props.workId}
            row={row}
            getCommunication={props.getCommunication} />
    }

    const exportToXls = async (filters: any, filename: string | undefined = undefined) => {
        const resp = await searchExportToXls(props.workId, 'communications/list.report', filters, i18next.languages[0]);
        downloadBlob(resp, filename ?? ('communications' + '.xlsx') ?? 'export.xlsx');
    }

    const exportCommunicationToXls = async (row: CommunicationRow) => {
        const resp = await searchExportToXls(props.workId, 'communications/report', { communicationId: row.id }, i18next.languages[0]);
        downloadBlob(resp, row.title + '.xlsx');
    }

    return <div className="c he CommunicationsList">
        {dialogs.render('communication', { maximized: true, title: t('communication') }, renderCommunication)}
        {dialogs.render('communication-receivers', { maximized: true, title: t('communications.receivers') }, renderReceivers)}
        {dialogs.render('communication-edit', { title: t('Edit end date') }, renderEditCommunication)}
        {dialogs.render('communication-delete', { title: t('Delete communication') }, renderRemoveCommunication)}
        <Portal container='#breadcrumb-right'>
            <div className='r g-20' style={{ alignItems: 'center' }}>
                <span className='exporter'>
                    <img
                        src='img/icons/excel-export-download.png'
                        className='pointer'
                        title={t('paginator.export')}
                        onClick={() => exportToXls(userFilters.merge({ workId: props.workId }))}
                        style={{ height: '23px', marginTop: '3px' }} />
                </span>
                <div>
                    {userFilters.renderAsButton({ className: 'fas fa-filter pointer' })}
                </div>
            </div>
        </Portal>
        {dataTable()}
        <span className='e'></span>
        {paginator()}
    </div>
}