import { Dialog } from 'primereact/dialog';
import { ConfirmDialog } from 'primereact/confirmdialog';
import * as React from 'react';
import { Loading } from '../components/custom/Loading';
import { ICountry, INotification, IOption, IPaginateResult, IPaginateSort } from '../models';
import { get as i18n } from '../i18n';
import { DataTable } from 'primereact/datatable';
import { Paginator } from 'primereact/paginator';
import { Column } from 'primereact/column';
import { classNames, confirmPopup, LoadingBox, Message, Portal, SplitButton, Toast, TreeTable, useTranslation } from '../components';
import * as DateUtils from '../utils/date-utils';
import TreeNode from 'primereact/treenode/treenode';
import { TreeTableExpandedKeysType } from 'primereact/treetable';
const __consumedNotifications: number[] = [];

export interface IToastOpts {
    life?: number;
    severity?: string;
    detail?: string;
}

const DEFAULT_TOAST_LIFE = 5000;
const DEFAULT_TOAST_SEVERITY = 'success';

export function observeValue<T>(handler: (data: T) => void, data: T) {
    const r = React.useRef<T>(data);

    React.useEffect(() => {
        if (r.current != data) {
            handler(data);
        }

        r.current = data;
    }, [data]);
}

export function useToast() {
    const ref = React.useRef<any>();

    const render = () => {
        return <Toast ref={ref} />;
    }

    const show = (message: string, opts: IToastOpts = {}) => {
        if (ref.current) {
            ref.current.show({
                life: opts.life ?? DEFAULT_TOAST_LIFE,
                severity: opts.severity ?? DEFAULT_TOAST_SEVERITY,
                summary: message,
                detail: opts.detail,
            });
        }
    }

    const error = (message: string, opts: IToastOpts = {}) => {
        show(message, {
            severity: 'error',
            detail: opts.detail,
            life: opts.life,
        });
    };

    return {
        render,
        error,
        show,
    }
}

export function onNotification(
    ctx: Partial<INotification>,
    notifications: INotification[],
    f: Function) {
    const consumedNotifications = React.useRef<number[]>(__consumedNotifications);
    const isConsumed = (id: number) => consumedNotifications.current.indexOf(id) >= 0;

    return React.useEffect(() => {
        const n = notifications
            .find(n => (ctx.action == undefined || n.action == ctx.action)
                && (ctx.ctx == undefined || n.ctx == ctx.ctx)
                && (ctx.type == undefined || n.type == ctx.type)
                && (ctx.id == undefined || n.id == ctx.id)
                && !isConsumed(n.id));
        if (n) {
            consumedNotifications.current.push(n.id);
            __consumedNotifications.push(n.id);
            f(n);
        }
    }, [notifications]);
}

export function useMemoized(fn: Function, dependencies: any[] = []) {
    const cache = React.useRef<any>({});

    React.useEffect(() => {
        cache.current = {};
    }, dependencies);

    return (...args: any[]) => {
        const key = JSON.stringify(args);
        if (cache.current[key]) {
            return cache.current[key];
        }
        else {
            const value = fn(...args);
            cache.current = { ...cache.current, [key]: value };

            return value;
        }
    }
}

export interface IConfirmOpts {
    text: string;
    confirmText?: string;
    cancelText?: string;
    onConfirm: any;
    onCancel: any;
}

export interface IUseDialogProps {
    className?: string;
    onShow?: Function;
}

export interface IRenderDialogOpts {
    className?: string;
    showCloseFooter?: boolean;
    title?: string | Function;
    maximized?: boolean;
    style?: any;
    maskClassName?: string;
    modal?: boolean;
    closable?: boolean;
    draggable?: boolean;
}

export function useDialogs(props: IUseDialogProps = {}) {
    const [dialogs, setDialogs] = React.useState<string[]>([]);
    const [dialogData, setDialogData] = React.useState<any>({});
    const [cnf, setCnf] = React.useState<any>(undefined);

    const confirm = (data: any) => setCnf(data);

    const get = (dialog: string) => dialogData[dialog];

    const show = (dialog: string, show: boolean | any = true) => {
        if (show && !dialogs.includes(dialog)) {
            props.onShow?.(show);
            setDialogs(dialogs.concat([dialog]));
            setDialogData((d: any) => ({ ...d, [dialog]: show }));
        }
        else if (!show) {
            setDialogs(dialogs.filter(d => d != dialog));
            setDialogData((d: any) => {
                delete d[dialog];
                return d;
            });
        }
    }

    const renderConfirm = (opts: IConfirmOpts) => {
        if (cnf) {
            return <ConfirmDialog
                visible
                acceptLabel={opts.confirmText ?? i18n('Confirm')}
                rejectLabel={opts.cancelText ?? i18n('Cancel')}
                accept={opts.onConfirm}
                reject={opts.onCancel}>
                {opts.text}
            </ConfirmDialog>
        }
        else {
            return null;
        }
    }

    const render = (
        dialog: string,
        inputOpts: IRenderDialogOpts | string = {},
        content: any) => {
        const opts = typeof (inputOpts) === 'object' ? inputOpts : { title: inputOpts };

        if (dialogs.indexOf(dialog) >= 0 && dialogData[dialog]) {
            const title = typeof (opts.title) == 'string'
                ? opts.title
                : typeof (opts.title) == 'function'
                    ? opts.title(dialogData[dialog])
                    : undefined;

            return <Dialog
                className={classNames(opts.className, props.className)}
                header={title}
                showHeader={title != undefined}
                style={opts.style}
                maskClassName={opts.maskClassName}
                maximized={opts.maximized}
                onHide={() => show(dialog, false)}
                closable={opts.closable}
                draggable={opts.draggable}
                modal={opts.modal}
                visible={true}>
                {content(dialogData[dialog])}
                {opts.showCloseFooter &&
                    <div className='footer r'>
                        <div className='e'></div>
                        <button onClick={() => show(dialog, false)}>{i18n('Close')}</button>
                    </div>}
            </Dialog>
        }
        else {
            return null;
        }
    }

    const showFromEvent = (name: string, data: any = undefined) => {
        return (..._: any) => {
            show(name, data);
        }
    }

    const clear = () => setDialogs([]);

    const clearConfirm = () => setCnf(undefined);

    const hide = (dialog: string) => show(dialog, false);

    return {
        clear,
        clearConfirm,
        confirm,
        hide,
        show,
        showFromEvent,
        render,
        renderConfirm,
        get,
        getState: () => ({ dialogs, dialogData, cnf }),
    };
}

export interface IFormDialogProps {
    id?: string;
    addTitle?: string;
    editTitle?: string;
    loading?: boolean;
    className?: string;
    editClassName?: string;
    addClassName?: string;
    portal?: string;
}

export function useFormDialog<T>(props: IFormDialogProps = {}) {
    const [showAdd, setShowAdd] = React.useState<any>(false);
    const [showEdit, setShowEdit] = React.useState<T | undefined>(undefined);
    const [errors, setErrors] = React.useState<any>({});

    const clear = () => {
        setErrors({});
        setShowAdd(false);
        setShowEdit(undefined);
    };

    const renderNormal = (fn: any, maximized: boolean | undefined = false) => {
        return <React.Fragment>
            {props.loading && <Loading />}
            {showAdd && <Dialog
                visible
                className={props.addClassName ?? props.className}
                style={maximized ? { width: '100vw', height: '100vh' } : undefined}
                maximized={maximized}
                header={props.addTitle}
                onHide={() => setShowAdd(false)}>
                {fn({
                    data: showAdd,
                    error: errors['add']
                })}
            </Dialog>}
            {showEdit && <Dialog
                visible
                className={props.editClassName ?? props.className}
                header={props.editTitle}
                maximized={maximized}
                style={maximized ? { width: '100vw', height: '100vh' } : undefined}
                onHide={() => setShowEdit(undefined)}>
                {fn(showEdit, {
                    error: errors['edit'],
                })}
            </Dialog>}
        </React.Fragment>
    }

    const render = (fn: any, maximized: boolean | undefined = false) => {
        if (props.portal) {
            return <Portal container={props.portal}>
                {renderNormal(fn, maximized)}
            </Portal>
        }
        else {
            return renderNormal(fn, maximized);
        }
    }

    const renderFooter = ({ loading, disabled, onSaveClicked }: { loading?: ILoading, disabled?: boolean, onSaveClicked?: Function } = {}) => {
        return <div className='r footer sm pd'>
            <span className='e' />
            {loading != undefined && loading.render()}
            <div className='p-buttonset'>
                <button
                    type='button'
                    onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        clear();
                    }}>
                    {i18n('Cancel')}
                </button>
                <button className='primary' onClick={() => onSaveClicked?.()} type='submit' disabled={disabled}>
                    <i className='pi pi-save sm pd-right' />
                    {i18n('Save')}
                </button>
            </div>
        </div>
    }

    const setEditError = (msg: string) => {
        const newerrors = { ...errors, edit: msg };

        setErrors(newerrors);
    }

    const clearErrors = () => {
        setErrors({});
    }

    return {
        render,
        renderFooter,
        clear,
        clearErrors,
        showAdd: () => setShowAdd(true),
        showEdit: (e: T) => setShowEdit(e),
        Footer: (props: any = undefined) => renderFooter(props),
        setEditError,
        currentEdit: showEdit,
    }
}

export interface IUseObjectStateProps {
    onChange?: Function;
    defaultValue?: any;
}

export function useObjectState<T>(data: T, opts: IUseObjectStateProps = {}) {
    const inhibitOnChange = React.useRef(false);
    const [value, setValue] = React.useState<any>(data);

    React.useEffect(() => {
        if (opts.onChange && !inhibitOnChange.current) {
            opts.onChange(value);
        }
        else if (inhibitOnChange.current) {
            inhibitOnChange.current = false;
        }
    }, [value]);

    const parseAsFloat = (input: any) => {
        const v = parseFloat(input);
        return isNaN(v) ? undefined : v;
    }

    const parseAsInt = (input: any) => {
        const v = parseInt(input);
        return isNaN(v) ? undefined : v;
    }

    const field = (fieldName: string, defaultValue: any = undefined) => {
        return value[fieldName] ?? defaultValue ?? opts.defaultValue;
    }

    const reset = (v: T) => {
        inhibitOnChange.current = true;
        setValue(v);
    }

    const set = (fieldName: string) =>
        (eventOrValue: any) => {
            inhibitOnChange.current = false;
            if (eventOrValue.target) {
                eventOrValue.persist && eventOrValue.persist();
                setValue((v: any) => ({ ...v, [fieldName]: eventOrValue.target.value }));
            }
            else {
                setValue((v: any) => ({ ...v, [fieldName]: eventOrValue }));
            }
        }

    const setFloat = (fieldName: string) =>
        (eventOrValue: any) => {
            inhibitOnChange.current = false;
            if (eventOrValue.target) {
                eventOrValue.persist && eventOrValue.persist();
                setValue((v: any) => ({ ...v, [fieldName]: parseAsFloat(eventOrValue.target.value) }));
            }
            else {
                setValue((v: any) => ({ ...v, [fieldName]: parseAsFloat(eventOrValue) }));
            }
        }

    const setInt = (fieldName: string) =>
        (eventOrValue: any) => {
            inhibitOnChange.current = false;
            if (eventOrValue.target) {
                eventOrValue.persist && eventOrValue.persist();
                setValue((v: any) => ({ ...v, [fieldName]: parseAsInt(eventOrValue.target.value) }));
            }
            else {
                setValue((v: any) => ({ ...v, [fieldName]: parseAsInt(eventOrValue) }));
            }
        }

    return {
        field,
        set,
        reset,
        setInt,
        setFloat,
        value,
    }
}

export interface IUseLoadingProps {
    container?: string;
}

export interface ILoading {
    loading: boolean;
    render: Function;
    renderBox: Function;
    renderModal: Function;
    isLoading: (name?: string | undefined) => boolean;
    start: Function;
    stop: Function;
    wrap: Function;
}

export function useLoading(props: IUseLoadingProps | boolean = false): ILoading {
    const lock = React.useRef<boolean>(false);
    const [loading, setLoading] = React.useState<any>({});

    React.useEffect(() => {
        if (typeof (props) === 'boolean') {
            setLoading({ 'global': props });
        }
    }, [props]);

    const wrapRender = (fn: Function) => {
        if (typeof (props) === 'object' && props.container) {
            return <Portal container={props.container}>{fn()}</Portal>
        }
        else {
            return fn();
        }
    }

    const renderBox = (ctx: string | undefined | any = undefined, optsArgs: any = {}) => {
        const ctxIsKey = typeof (ctx) === 'string' || ctx == undefined;
        const k = ctxIsKey ? (ctx || 'global') : 'global';
        const isLoading = loading[k];
        const opts = typeof (ctx) === 'object' ? ctx : optsArgs;

        if (isLoading) {
            return wrapRender(() =>
                <div className={'c center ' + opts.className} style={opts.style}>
                    <LoadingBox />
                    {opts.text && <div className='md pd-top loading-text'>
                        {opts.text}
                    </div>}
                </div>
            )
        }
        else {
            return null;
        }
    }

    const renderModal = (ctx: string | undefined = undefined, optsArgs: any = {}) => {
        const ctxIsKey = typeof (ctx) === 'string' || ctx == undefined;
        const k = ctxIsKey ? (ctx || 'global') : 'global';
        const isLoading = loading[k];
        const opts = typeof (ctx) === 'object' ? ctx : optsArgs;

        if (isLoading) {
            return wrapRender(() =>
                <div className={'c center modal-container ' + opts.className} style={opts.style}>
                    <Loading />
                    {opts.text && <div className='md pd-top loading-text'>
                        {opts.text}
                    </div>}
                </div>
            )
        }
        else {
            return null;
        }
    }

    const render = (ctx: string | undefined = undefined) => {
        const k = ctx || 'global';
        const isLoading = loading[k];

        return wrapRender(() =>
            <React.Fragment>
                {isLoading && <Loading />}
            </React.Fragment>
        );
    }

    const start = (ctx: string | undefined = undefined) => {
        if (lock.current) {
            return;
        }
        lock.current = true;
        const k = ctx || 'global';
        const n = { ...loading, [k]: true };

        setLoading(n);
    }

    const stop = (ctx: string | undefined = undefined) => {
        lock.current = false;
        const k = ctx || 'global';
        const n = { ...loading };
        delete n[k];

        setLoading(n);
    }

    const isLoading = (ctx: string | undefined = undefined) => {
        const k = ctx || 'global';

        return loading[k] as boolean;
    }

    const wrap = (fn: Function) => async (...params: any) => {
        start();
        const res = await fn(...params);
        stop();

        return res;
    }

    return {
        loading: loading['global'] ?? false,
        render,
        renderBox,
        renderModal,
        isLoading,
        start,
        stop,
        wrap,
    }
}

type ColumnDelegate = 'date' | 'datetime' | 'boolean' | undefined;

interface IUseDataTableColumn<T> {
    field?: string;
    className?: string;
    title?: string;
    render?: (_: T) => any;
    delegate?: ColumnDelegate;
    sortKey?: string;
    sortable?: boolean;
}

interface IUseDataTableSplitAction<T> {
    actions: (IUseDataTableActions<T> | undefined)[];
    className?: string;
    headerClassName?: string;
}

interface IUseDataTableActions<T> {
    icon?: string | ((_: T) => string);
    text?: string;
    title?: string;
    onClick: (_: T, event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    disabled?: boolean | ((_: T) => boolean | undefined);
    tooltip?: string | ((_: T | any) => string);
    className?: string;
    headerStyle?: any;
    headerClassName?: string;
    bodyClassName?: string;
}

type SortHandler = (sortField: string, sortAsc: boolean) => void;

export interface IUseDataTableProps<T> {
    actions?: (IUseDataTableActions<T> | IUseDataTableSplitAction<T> | undefined)[];
    className?: string;
    columns: (IUseDataTableColumn<T> | string | undefined)[];
    data: T[];
    loading?: any;
    scrollable?: boolean;
    scrollHeight?: string;
    style?: any;
    tableStyle?: any;
    onRowClicked?: Function;
    selection?: any;
    onSelectionChange?: Function;
    onSort?: SortHandler;
    tooltip?: boolean;
    resizableColumns?: boolean;
    lazy?: boolean;
    paginator?: boolean;
    rows?: number;
    rowsPerPageOptions?: number[];
    paginatorTemplate?: string;
    emptyMessage?: string;
    denyLocalSort?: boolean;
}

export interface IRenderDataTableOptions {
    header?: any;
}

function columnIsDataTableColumn<T>(c: any): c is IUseDataTableColumn<T> {
    return c && (c.field || c.title || c.sortKey);
}

const dateDelegate = (d: any) => {
    if (d) {
        try {
            return DateUtils.format(d, 'd-m-y');
        }
        catch (e) {
            return d;
        }
    }
    else {
        return null;
    }
}

const dateTimeDelegate = (d: any) => {
    if (d) {
        return DateUtils.format(d, 'd-m-y h:i:s');
    }
    else {
        return null;
    }
}

const booleanDelegate = (d: any) => {
    if (d) {
        return <i className='pi pi-check boolean-true' />;
    }
    else {
        return <div className='boolean-false'> </div>;
    }
}

const createDelegate = (d: ColumnDelegate) => {
    if (d && d === 'date') {
        return dateDelegate;
    }
    else if (d && d === 'datetime') {
        return dateTimeDelegate;
    }
    else if (d && d === 'boolean') {
        return booleanDelegate;
    }
    else {
        return undefined;
    }
}

function actionIsMultiple<T>(a: IUseDataTableSplitAction<T> | IUseDataTableActions<T> | undefined): a is IUseDataTableSplitAction<T> {
    return (a as IUseDataTableSplitAction<T>)?.actions != undefined;
}

export function useDataTable<T>(props: IUseDataTableProps<T>) {

    const [currentSortField, setCurrentSortField] = React.useState<string | undefined>();
    const [currentSortOrder, setCurrentSortOrder] = React.useState<any>();

    const wrapTooltip = (fieldName: string | undefined) =>
        props.tooltip && fieldName
            ? (r: any) => {
                if (typeof (r) === 'string') {
                    return <span title={r}>{r}</span>
                }
                else {
                    return <span title={r[fieldName]}>{r[fieldName]}</span>
                }
            }
            : null;

    const renderColumn = (c: string | IUseDataTableColumn<T>, i: number) => {
        const cap = (str: string) =>
            str ? str.charAt(0).toUpperCase() + str.slice(1)
                : str;

        if (typeof (c) === 'string') {
            const render = wrapTooltip(c);
            return <Column
                header={i18n(cap(c))}
                body={render}
                bodyClassName='text-ellision'
                key={i}
                field={c} />
        }
        else {
            const delegateF = createDelegate(c.delegate);
            const delegate = delegateF
                ? (r: any) => delegateF(c.field ? r[c.field] : r)
                : undefined;

            const render = wrapTooltip(c.field);
            return <Column
                bodyClassName={classNames('text-ellision', c.className)}
                key={i}
                sortField={c.sortKey ? c.sortKey : c.sortable ? c.field : undefined}
                sortable={c.sortKey != undefined || c.sortable}
                header={c.title ? i18n(c.title) : undefined}
                field={c.field}
                headerClassName={c.className}
                body={c.render ?? delegate ?? render} />
        }
    }

    const renderMultipleAction = (a: IUseDataTableSplitAction<T>, data: T, index: number) => {
        const actions = a
            .actions
            .filter(a => a != undefined)
            .map(a => a as any)
            .filter(a => a.disabled === undefined
                || (typeof (a.disabled) === 'boolean' && a.disabled != true)
                || (typeof (a.disabled) === 'function' && a.disabled(data) != true))
            .map((s) => {
                const uniqueId = `split_button_${index}`;
                return ({
                    icon: s.icon,
                    label: s.title,
                    command: (e: any) => {
                        const id = uniqueId;
                        s.onClick(data, {
                            target: document.getElementById(id),
                            id,
                        });
                    }
                })
            });

        if (actions.length > 0) {
            return <SplitButton
                id={`split_button_${index}`}
                className='datatable-split-btn'
                model={actions} />;
        }
        else {
            return null;
        }
    }

    const renderAction = (a: IUseDataTableActions<T> | IUseDataTableSplitAction<T> | undefined, i: number) => {
        if (actionIsMultiple(a)) {
            return <Column
                key={`action_${i}`}
                bodyClassName='center'
                headerClassName={classNames('datatable-split-cell-container', a.headerClassName)}
                body={(c) => renderMultipleAction(a, c, props.data.indexOf(c))} />
        }
        else if (a) {
            const disabled = (c: T) => {
                return typeof (a.disabled) === 'boolean'
                    ? a.disabled
                    : a.disabled
                        ? a.disabled(c)
                        : false;
            }

            return <Column
                key={`action_${i}`}
                header={a.title ? i18n(a.title) : undefined}
                headerClassName={!a.text ? 'td-sm' : classNames('center', a.headerClassName)}
                headerStyle={a.headerStyle}
                bodyClassName={'center ' + a.bodyClassName}
                body={c => {
                    if (disabled(c)) {
                        return null;
                    }
                    else if (a.text) {
                        return <button
                            className={a.className}
                            title={typeof (a.tooltip) === 'string' ? a.tooltip : a.tooltip?.(c)}
                            onClick={(e) => a.onClick(c, e)}>{i18n(a.text)}</button>
                    }
                    else if (a.icon && typeof (a.icon) === 'string' && a.icon.startsWith('fa')) {
                        return <i
                            className={`${a.icon} pointer`}
                            title={typeof (a.tooltip) === 'string' ? a.tooltip : a.tooltip?.(c)}
                            onClick={(e) => a.onClick(c, e)}></i>;
                    }
                    else if (a.icon && typeof (a.icon) === 'string') {
                        return <i
                            className={`pi pi-${a.icon} pointer`}
                            title={typeof (a.tooltip) === 'string' ? a.tooltip : a.tooltip?.(c)}
                            onClick={(e) => a.onClick(c, e)}></i>;
                    }
                    else if (a.icon && typeof (a.icon) === 'function') {
                        const icon = a.icon(c);
                        return <i
                            className={`${icon} pointer`}
                            title={typeof (a.tooltip) === 'string' ? a.tooltip : a.tooltip?.(c)}
                            onClick={(e) => a.onClick(c, e)}></i>;
                    }
                    else {
                        return null;
                    }
                }} />;
        }
        else {
            return null;
        }
    }

    const onRowClicked = (event: any) => {
        if (props.onRowClicked) {
            props.onRowClicked(event.data);
        }
    }

    const doSort = ({ sortField, sortOrder }: { sortField: string, sortOrder: any }) => {

        setCurrentSortField(sortField);
        setCurrentSortOrder(sortOrder);

        props.onSort?.(sortField, sortOrder == 1);
    }

    return (opts: IRenderDataTableOptions = {}) => {
        return <>
            {props.loading && props.loading.isLoading && props.loading.isLoading() && props.loading.renderBox()}
            <DataTable
                sortMode='single'
                onSort={props.denyLocalSort ? undefined : doSort}
                sortField={currentSortField}
                sortOrder={currentSortOrder}
                value={props.data}
                rowHover={props.onRowClicked != undefined}
                selectionMode={props.onRowClicked ? 'single' : undefined}
                selection={props.selection}
                onSelectionChange={e => props.selection && props.onSelectionChange ? props.onSelectionChange(e.data) : undefined}
                style={props.style}
                className={classNames('table', props.className)}
                header={opts.header}
                scrollHeight={props.scrollHeight}
                tableStyle={props.tableStyle}
                onRowClick={onRowClicked}
                emptyMessage={i18n(props.emptyMessage ?? 'No data available')}
                scrollable={props.scrollable}
                resizableColumns={props.resizableColumns}
                paginator={props.paginator}
                rows={props.rows}
                rowsPerPageOptions={props.rowsPerPageOptions}
                totalRecords={props.data?.length}
                paginatorTemplate={props.paginator ? "RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" : undefined}
                currentPageReportTemplate={props.paginator ? "{first} - {last} de {totalRecords}" : undefined}>
                {props
                    .columns
                    .filter(c => c != undefined)
                    .map((r, i) => renderColumn(r!, i))}
                {props.actions && props.actions.map(renderAction)}
            </DataTable>
        </>
    }
}

export interface IUseTreeTableProps<T> {
    data: T[];
    columns: (IUseDataTableColumn<T> | string | undefined)[];
    actions?: (IUseDataTableActions<T> | undefined)[];
    idProperty?: string;
    parentProperty?: string;
    childrenProperty?: string;
    sortProperty?: string;
    expand?: boolean;
    toggleApplications?: boolean;
    selectable?: boolean;
}

export function useTreeTable<T>(props: IUseTreeTableProps<T>) {
    const [nodes, setNodes] = React.useState<TreeNode[]>([]);
    const [expandedKeys, setExpandedKeys] = React.useState<TreeTableExpandedKeysType>({});

    const createKey = (d: any) =>
        d.key ??
        `${d.__typename}_${d.id}_${d[props.parentProperty ?? 'root']}`;

    const createNode = (d: any) => {
        return {
            data: d,
            key: d.key ?? createKey(d),
            children: [],
        } as any
    }

    const prepareFlatNodes = (data: any[], key: string, parentKey: string) => {
        const stack: any[] = [];
        const res: TreeNode[] = [];
        const all: any = {};
        const nodesAll: any = {};

        const availableIds: any[] = data.map(d => d[key]);

        for (const d of data) {
            all[d[key]] = d;
            const parentId = d[parentKey];
            if (parentId) {
                stack.push(d);
            }
            else {
                const n = createNode(d);
                nodesAll[d[key]] = n;
                res.push(n);
            }
        }

        let count = 0;
        while (stack.length > 0 && count < 10000) {
            const d = stack.pop();
            const parentId = d[parentKey];
            if (nodesAll[parentId]) {
                const newNode = createNode(d);
                nodesAll[d[key]] = newNode;
                nodesAll[d[parentKey]].children.push(newNode);
                if (props.sortProperty) {
                    nodesAll[d[parentKey]].children =
                        nodesAll[d[parentKey]]
                            .children
                            .sort((a: any, b: any) => a.data[props.sortProperty!] - b.data[props.sortProperty!]);
                }

            } // tengo un padre que no existe
            else if (!availableIds.includes(parentId)) {
                const n = createNode(d);
                nodesAll[d[key]] = n;
                res.push(n);
            }
            else {
                stack.splice(0, 0, d);
                count++;
            }
        }

        if (props.expand) {
            let _expandedKeys = { ...expandedKeys };

            for (const k of data.map((d: any) => d.key ?? createKey(d))) {
                _expandedKeys[k as string] = true;
            }

            setExpandedKeys(_expandedKeys);
        }

        setNodes(res);
    }

    const prepareChildrenNodes = (data: any[]) => {
        const res: TreeNode[] = [];

        for (const d of data) {
            const node = createNode(d)
            res.push(node);

            const children = prepareChildrenNodes(d[props.childrenProperty!] ?? []);
            for (const ch of children) {
                node.children.push(ch);
            }
        }

        return res;
    }

    const prepareNodes = (data: any[], key: string, parentKey: string) => {
        if (props.childrenProperty) {
            const res = prepareChildrenNodes(data);
            setNodes(res);
            if (props.expand) {
                setExpandedKeys((keys: any) => {
                    for (const k of res.map(r => r.key ?? '')) {
                        keys[k as string] = true;
                    }

                    return keys;
                });
            }
        }
        else {
            return prepareFlatNodes(data, key, parentKey);
        }
    }

    React.useEffect(() => {
        prepareNodes(
            props.data,
            props.idProperty ?? 'id',
            props.parentProperty ?? 'parentId');
    }, [props.data]);

    const renderColumn = (c: string | IUseDataTableColumn<T>, i: number) => {
        const cap = (str: string) =>
            str ? str.charAt(0).toUpperCase() + str.slice(1)
                : str;

        const key = `col_${i}`;

        if (typeof (c) === 'string') {
            return <Column
                expander={i == 0}
                header={i18n(cap(c))}
                bodyClassName='text-ellision'
                key={key}
                field={c ?? key} />
        }
        else {
            const delegateF = createDelegate(c.delegate);
            const delegate = delegateF
                ? (r: any) => delegateF(r)
                : undefined;

            const resolveFn = (node: any) => {
                const data = c.field ? node.data[c.field] : node.data;
                if (c.render) {
                    return c.render(data);
                }
                else if (delegate) {
                    return delegate(data);
                }
            }

            return <Column
                expander={i == 0}
                bodyClassName={classNames('text-ellision', c.className)}
                key={key}
                sortField={c.sortKey}
                sortable={c.sortKey != undefined}
                header={c.title ? i18n(c.title) : undefined}
                field={c.field ?? key}
                headerClassName={c.className}
                body={c.render || delegate ? resolveFn : undefined} />
        }
    }

    const renderAction = (a: IUseDataTableActions<T> | undefined, i: number) => {
        if (a) {
            const disabled = (c: T) => {
                return typeof (a.disabled) === 'boolean'
                    ? a.disabled
                    : a.disabled
                        ? a.disabled(c)
                        : false;
            }
            const key = `action_${i}`;
            return <Column
                key={key}
                field={key}
                header={a.title ? i18n(a.title) : ' '}
                headerClassName={!a.text ? 'td-sm' : 'center'}
                bodyClassName='no-padding center'
                body={(node: TreeNode) => {
                    const c = node.data;
                    if (disabled(c)) {
                        return null;
                    }
                    else if (a.text) {
                        return <button
                            title={typeof (a.tooltip) === 'string' ? a.tooltip : a.tooltip?.(c)}
                            onClick={(e) => a.onClick(c, e)}>{i18n(a.text)}</button>
                    }
                    else if (a.icon && typeof (a.icon) === 'string' && a.icon.startsWith('fa')) {
                        return <i
                            className={`${a.icon} pointer`}
                            title={typeof (a.tooltip) === 'string' ? a.tooltip : a.tooltip?.(c)}
                            onClick={(e) => a.onClick(c, e)} />;
                    }
                    else if (a.icon && typeof (a.icon) === 'function') {
                        const icon = a.icon(c);
                        return <i
                            className={`${icon} pointer`}
                            title={typeof (a.tooltip) === 'string' ? a.tooltip : a.tooltip?.(c)}
                            onClick={(e) => a.onClick(c, e)} />;
                    }
                    else {
                        return <i
                            className={`pi pi-${a.icon} pointer`}
                            title={typeof (a.tooltip) === 'string' ? a.tooltip : a.tooltip?.(c)}
                            onClick={(e) => a.onClick(c, e)} />;
                    }
                }} />;
        }
        else {
            return null;
        }
    }

    return (opts: IRenderDataTableOptions = {}) => {
        const columns = props.columns ?
            props
                .columns
                .filter(c => c != undefined)
                .map((r, i) => renderColumn(r!, i))
            : [];
        const actions = props.actions
            ? props.actions.map(renderAction)
            : [];

        const children = [...columns, ...actions];

        return <TreeTable
            expandedKeys={expandedKeys}
            resizableColumns={true}
            columnResizeMode='fit'
            showGridlines
            stripedRows
            onToggle={(e) => setExpandedKeys(e.value)}
            value={nodes}
            className='table'
            selectionMode={props.selectable ? 'single' : undefined}
            header={opts.header}>
            {children}
        </TreeTable>;
    }
}

export interface IUseConfirmOpts {
    message?: string;
    icon?: string;
    accept?: any;
    reject?: any;
    target?: Function;
}

export interface IUseConfirmRuntimeOpts {
    event: any;
}

export function useConfirm(opts: IUseConfirmOpts = {}) {
    const { t } = useTranslation();

    const confirm = (args: (Partial<IUseConfirmOpts> & IUseConfirmRuntimeOpts) | any, event: any = undefined) => {
        const o = typeof (args) === 'object' && args.target == undefined
            ? args
            : {};
        const target = (args.target
            ? args.target
            : args.event
                ? args.event?.target
                : event?.target
                    ? event?.target
                    : event?.originalEvent?.target) ?? opts.target?.();

        const performReject = () => {
            const a = opts.reject ?? o.reject;
            if (a) {
                a(args);
            }
        }

        const performAccept = () => {
            const a = opts.accept ?? o.accept;
            if (a) {
                a(args);
            }
        }

        confirmPopup({
            target: target,
            message: (o && o.__typename) ? opts.message : (o.message ?? opts.message),
            icon: o.icon ?? opts.icon ?? 'pi pi-exclamation-triangle',
            accept: performAccept,
            reject: performReject,
            acceptLabel: t('confirm.accept'),
            rejectLabel: t('confirm.reject'),
        });
    }

    return confirm;
}

export function useStringState(defaultValue: string | undefined = undefined) {
    const [state, setState] = React.useState<string | undefined>(defaultValue);

    const setFromEvent = (event: any) => {
        if (event && event.target && event.target.value) {
            setState(event.target.value);
        }
        else if (event && typeof (event) === 'string') {
            setState(event);
        }
        else {
            setState(undefined);
        }
    }

    const clear = () => setState(undefined);

    return {
        hasValue: () => state != undefined,
        clear,
        value: state,
        set: setFromEvent,
    }
}

export function useNumberState(defaultValue: number | undefined = undefined) {
    const [state, setState] = React.useState<number | undefined>(defaultValue);

    const setFromEvent = (event: any | undefined) => {
        if (event && event.target?.value) {
            setState(Number(event.target.value));
        }
        else if (typeof (event) === 'number') {
            setState(event);
        }
        else {
            setState(undefined);
        }
    }

    const clear = () => setState(undefined);

    return {
        clear,
        hasValue: () => state != undefined,
        value: state,
        set: setFromEvent,
    }
}

export function useBooleanState(defaultValue: boolean | undefined = undefined) {
    const [state, setState] = React.useState<boolean | undefined>(defaultValue);

    const setFromEvent = (event: any | undefined) => {
        if (event && event.target.value) {
            setState(event.target.value === 'true');
        }
        else if (typeof (event) === 'boolean') {
            setState(event);
        }
        else {
            setState(undefined);
        }
    }

    const setFromBoolean = (value: boolean | undefined) => {
        setState(value);
    }

    const clear = () => setState(undefined);

    return {
        clear,
        hasValue: () => state != undefined,
        value: state,
        set: setFromEvent,
        setFromBoolean,
    }
}

export interface IResolveNameOpts {
    id?: string;
    name?: string;
    defaultName?: string;
    translate?: boolean;
}

export function useResolveName<T>(data: T[], opts: IResolveNameOpts = {}) {
    const [cache, setCache] = React.useState<any>({});
    const keyProperty = opts.id ?? 'id';
    const nameProperty = opts.name ?? 'name';

    React.useEffect(() => {
        setCache({});
    }, [data]);

    const resolve = (value: number | string | undefined) => {
        if (value && cache[value]) {
            return cache[value];
        }
        else if (value) {
            const d: any = data?.find((obj: any) => obj[keyProperty] === value);
            if (d) {
                const resolvedValue = opts.translate
                    ? i18n(d[nameProperty])
                    : d[nameProperty];
                setCache((c: any) => ({ ...c, [value]: resolvedValue }));
                return resolvedValue;
            }
            else {
                return opts.defaultName;
            }
        }
        else {
            return opts.defaultName;
        }
    }

    return resolve;
}

export interface IUseMessage {
    errorMessage?: string;
    successMessage?: string;
    autoClear?: boolean;
    clearAfterMillis?: number;
}

const AUTO_CLEAR_MILLIS = 5000;

export function useMessage(opts: IUseMessage = {}) {
    const [error, setError] = React.useState<string | undefined>();
    const [success, setSuccess] = React.useState<any | undefined>();
    const [successMessage, setSuccessMessage] = React.useState<string | undefined>(opts.successMessage);

    const clear = () => {
        setError(undefined);
        setSuccess(undefined);
    }

    const setFromOption = (result: IOption<any>, overrideOpts: Partial<IUseMessage> = {}) => {
        clear();

        if (result.error || result.isError) {
            setError(i18n(result.error || overrideOpts.errorMessage || 'error'));
        }
        else if (result.hasValue) {
            setSuccess(result.value);
            setSuccessMessage(overrideOpts.successMessage ?? opts.successMessage);
        }

        if (opts.autoClear) {
            setTimeout(clear, AUTO_CLEAR_MILLIS);
        }

        if (opts.clearAfterMillis) {
            setTimeout(clear, opts.clearAfterMillis);
        }
    }

    const render = (fn: Function | undefined = undefined) => {
        const comp = success
            ? <Message severity='success' text={successMessage} />
            : error
                ? <Message severity='error' text={error ?? opts.errorMessage} />
                : null;

        if (comp && fn) {
            return fn(comp);
        }
        else {
            return comp;
        }
    }

    const renderIfError = (fn: Function | undefined = undefined) => {
        const comp = success
            ? null
            : error
                ? <Message severity='error' text={error ?? opts.errorMessage} />
                : null;

        if (comp && fn) {
            return fn(comp);
        }
        else {
            return comp;
        }
    }

    const hasError = () => error != undefined;

    const isSuccess = () => success != undefined;

    return {
        isSuccess,
        set: setFromOption,
        setError,
        clear,
        getError: () => error,
        hasError,
        render,
        renderIfError
    }
}

export interface IUseErrorOpts { }

export function useError(opts: IUseErrorOpts = {}) {
    const [value, setValue] = React.useState<string | undefined>();

    const clear = () => {
        setValue(undefined);
    }

    const setFromOption = (result: IOption<number | boolean>) => {
        if (result.error) {
            setValue(result.error);
        }
        else {
            clear();
        }
    }

    const render = () => {
        if (value) {
            return <Message severity='error' text={value} />
        }
        else {
            return null;
        }
    }

    const hasError = () => value != undefined;

    return {
        set: setValue,
        clear,
        hasError,
        render,
        setFromOption,
    }
}

export interface IUseRemoteData<T> {
    lazy?: boolean;
    parameters?: any;
    disabled?: boolean;

    map?: (input: T) => T,
}

export interface IRemoteData<T> {
    query: (...params: any) => Promise<T>;
    value: T;
    loading: ILoading;
    isLoading: () => boolean,
    hasValues: () => boolean,
    renderLoading: Function,
}

export function useRemoteData<T>(provider: Function, opts: IUseRemoteData<T> = {}): IRemoteData<T> {
    const [data, setData] = React.useState<T | undefined>();
    const loading = useLoading(!opts.lazy);

    const initialize = async (...parameters: any) => {
        loading.start();

        const finalParameters = parameters && parameters.length > 0
            ? parameters
            : opts.parameters && opts.parameters.length
                ? opts.parameters
                : [opts.parameters];

        const q = provider(...finalParameters);

        let resp: any = undefined;
        if (q.then) {
            resp = await q;
        }
        else {
            resp = q;
        }
        const result: T = opts.map ? opts.map(resp) : resp;

        setData(result);

        loading.stop();

        return result;
    }

    React.useEffect(() => {
        if (!opts.lazy && opts.disabled != true) {
            initialize();
        }
    }, []);

    const query = (...params: any) => {
        return initialize(...params);
    }

    const renderLoading = (opts: any = undefined) => {
        return loading.renderBox(opts);
    }

    return {
        query,
        value: data as T,
        loading,
        isLoading: loading.isLoading,
        renderLoading,
        hasValues: () => data != undefined && !loading.isLoading(),
    }
}

export interface IPaginatorOpts<T> {
    className?: string;
    data: IPaginateResult<T> | undefined,
    onChange: (page: number, rows: number | undefined) => void,
    onExport?: () => void,
    rowNumbers?: number[],
}

export function usePaginator<T>(opts: IPaginatorOpts<T>) {
    const { t } = useTranslation();
    const [currentPage, setCurrentPage] = React.useState<number>(0);
    const [currentRows, setCurrentRows] = React.useState<number>(opts.rowNumbers?.[0] ?? 10);

    const doOnChange = (p: any) => {
        if (p.page != currentPage || p.rows != currentRows) {
            opts.onChange(p.page, p.rows);
        }
        setCurrentPage(p.page);
        setCurrentRows(p.rows);
    }

    const render = (fn?: any) => {
        const first = opts.data ? opts.data.page * opts.data.limit : 0;

        return <div className={classNames('r paginator-container', opts.className)}>
            <Paginator
                className='paginator e'
                first={first}
                rows={opts.data?.limit}
                totalRecords={opts.data?.total}
                rowsPerPageOptions={opts.rowNumbers ?? [10, 20, 50, 100]}
                onPageChange={p => doOnChange(p)} />
            <span className='paginator-total-text'>{opts.data?.data?.length}/{opts.data?.total}</span>
            {opts.onExport != undefined && <span className='exporter'>
                <i className='pointer fas fa-file-excel'
                    title={t('paginator.export')}
                    onClick={() => opts.onExport && opts.onExport()} />
            </span>}
            {fn != undefined && fn}
        </div>
    }

    return render;
}

export function useResolveCountryAndRegion(countries: ICountry[]) {
    const resolve = (data: any) => {
        const { countryId, regionId, countyId } = data;

        const country = countries.find(c => c.id == countryId);
        const region = country
            ? country.regions.find(r => r.id == regionId)
            : undefined;
        const county = region
            ? region.counties.find(c => c.id == countyId)
            : undefined;

        return [country, region, county]
            .filter(t => t != undefined)
            .map(t => t?.name)
            .join(', ');
    }

    return resolve;
}

type IUseKeyListener = {
    handle?: Function;
    key?: string;
    keyCode?: number;
    keyCtrl?: boolean;
}

function isUseKeyListenerOpts(a: any): a is IUseKeyListener {
    return typeof (a.handle) === 'function';
}

export function useKeyListener(fn: Function | IUseKeyListener, rawOpts: IUseKeyListener = {}) {
    const opts = isUseKeyListenerOpts(fn) ? fn : { ...rawOpts, handle: fn };

    const shouldHandleEvent = (event: React.KeyboardEvent<any>) => {
        let handle = true;
        if (opts.key) {
            handle = handle && event.key === opts.key;
        }
        if (opts.keyCode) {
            handle = handle && event.keyCode === opts.keyCode;
        }
        if (opts.keyCtrl) {
            handle = handle && event.ctrlKey === opts.keyCtrl;
        }

        return handle;
    }

    const handleKey = (event: any) => {
        if (shouldHandleEvent(event)) {
            event.preventDefault();
            event.stopPropagation();

            opts.handle?.(event);

            return false;
        }
    };

    React.useEffect(() => {
        document.addEventListener('keydown', handleKey);

        return () => {
            document.removeEventListener('keydown', handleKey);
        }
    }, []);
}

export function useHideBodyScrollbar() {
    const hide = () => {
        document.body.classList.add('no-scroll');
    }

    const show = () => {
        document.body.classList.remove('no-scroll');
    }

    React.useEffect(() => {
        hide();

        return show;
    }, []);
}

export function useScheduler() {
    const s = React.useRef(false);

    const schedule = (fn: Function, delay: number) => {
        if (s.current === false) {
            s.current = true;
            setTimeout(() => {
                s.current = false;
                // console.log('scheduler:fire');
                fn();
            }, delay);
        }
        else {
            // console.log('scheduler:inhibit');
        }
    }

    return {
        schedule: schedule
    }
}

export const useCallbackState = (initialValue: any) => {
    const [state, _setState] = React.useState<any>(initialValue);
    const callbackQueue = React.useRef<any>([]);
    React.useEffect(() => {
        callbackQueue.current.forEach((cb: any) => cb(state));
        callbackQueue.current = [];
    }, [state]);

    const setState = (newValue: any, callback: any) => {
        _setState(newValue);
        if (callback && typeof callback === "function") {
            callbackQueue.current.push(callback);
        }
    };
    return [state, setState];
};

export function useDebounce(value: any, delay: number) {
    const [debouncedValue, setDebouncedValue] = React.useState(value);
    React.useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);
        return () => clearTimeout(handler);
    }, [value, delay]);
    return debouncedValue;
}

type DispatcherProps = {
    dispatch: Function,
    handlers: any,
    delay?: number | undefined,
};

export const useDispatcher = (props: DispatcherProps) => {
    const DEFAULT_DELAY = 500;
    const ts = React.useRef<number>(0);
    const sent = React.useRef<boolean>(false);
    const values = React.useRef<any>({});

    const filterValues = () => {
        const res: any = {};

        for (const k of Object.keys(values.current)) {
            if (values.current[k] && values.current[k] != '') {
                res[k] = values.current[k];
            }
        }

        return res;
    }

    const enqueue = () => {
        const nts = new Date().getTime();
        if (ts.current == 0) {
            ts.current = nts;
        }
        if (nts - ts.current > (props.delay ?? DEFAULT_DELAY)) {
            if (sent.current) {
                return;
            }
            sent.current = true;
            ts.current = nts;
            props.dispatch(filterValues());
        }
        else {
            setTimeout(enqueue, props.delay ?? DEFAULT_DELAY);
        }
    }

    const append = (name: string, event: any) => {
        const value = event.target.value;
        props.handlers[name]?.(value);
        if (values.current[name] != value) {
            sent.current = false;
            values.current = { ...values.current, [name]: value };
            setTimeout(enqueue, props.delay ?? DEFAULT_DELAY);
        }
    }

    return {
        onEvent: (name: string) => (e: any) => append(name, e),
        append,
    }
}

export function onChange(callback: Function, deps: any[]) {
    const prev = React.useRef<string>(JSON.stringify(deps));

    React.useEffect(() => {
        if (prev.current != JSON.stringify(deps)) {
            prev.current = JSON.stringify(deps);
            callback();
        }
    }, [deps]);
}

export default {
    onChange,
    useCallbackState,
    useHideBodyScrollbar,
    onNotification,
    useDataTable,
    useDialogs,
    useError,
    useFormDialog,
    useLoading,
    useMemoized,
    useNumberState,
    usePaginator,
    useResolveName,
    useResolveCountryAndRegion,
    useKeyListener,
    useScheduler,
    observeValue,
    useDispatcher,
}

