import * as React from 'react';

import { Portal, RequirePermission, RequireRole, TogglePanel, useDialogs, useLoading, useRemoteData, useTranslation } from '@components';
import JobAuthorizeForm from '@containers/jobs/JobAuthorizeFormContainer';
import JobRevokeForm from '@containers/jobs/JobRevokeFormContainer';
import JobFinalizeForm from '@containers/jobs/JobFinalizeFormContainer';
import JobReactivateForm from '@containers/jobs/JobReactivateFormContainer';
import { IContractor, IJob, IJobHasContractor, IJobSettings, IJobStatus, ISecurity, IWork, JobStatusType, ModuleManager } from '@models';
import { goToJobAddContractor, goToJobInformationLoadedControl } from '../routes';
import { redirectTo } from '@utils';

import JobStatus from '@containers/jobs/JobStatusContainer';

import './JobPanelActions.scss';
import { getJobHasContractorFromAppUser, getJobHasContractorFromId } from '@store/actions/contractors';
import { IRequirement, RequirementStatusTypes, RequirementTargetType } from '@models/requirements';

export interface IProps {
    children?: any;
    job: IJob;
    jobSettings?: IJobSettings;
    jobStatus: IJobStatus[];
    reload: Function;
    security: ISecurity;
    clearSelectedContractor: Function;
    selectedJobHasContractor?: IJobHasContractor;
    selectedContractor: IContractor | undefined;
    workId: number;
    getContractorFromAppUser: Function;
    work: IWork;
    getRequirements: Function;
    moduleManager: ModuleManager;
}

const loadRequirements = (workId: number, jobId: number) => {
    redirectTo(`/work/${workId}/jobs/${jobId}/requirements`);
}

function JobPanelActionsImpl(props: IProps) {
    const { t } = useTranslation();
    const [currentState, setCurrentState] = React.useState<IJobStatus | undefined>();
    const [selfContractor, setSelfContractor] = React.useState<IContractor | undefined>();
    const isNotified = React.useRef<boolean>(false);
    const anyPending = React.useRef<boolean>(false);
    const [canAddSubContractorState, setCanAddSubContractorState] = React.useState<boolean>(true);

    React.useEffect(() => {
        if (props.jobStatus) {
            const c = props.jobStatus.find(s => s.isCurrent);
            setCurrentState(c);
        }
    }, [props.jobStatus]);

    const dialogs = useDialogs();

    const reloadJob = () => {
        dialogs.clear();
        props.reload();
    }

    const goToAddSubContractor = async () => {
        const currentJobHasContractor = await getJobHasContractorFromAppUser(props.workId, props.job.id);
        goToJobAddContractor(props.workId, props.job.id, currentJobHasContractor.id);
    }

    const mainDepartment = props.job.departments
        .find(r => r.isMain == true);

    const canSave = (policies: string = '') => {

        if (props.security.isGestor()) {
            return true;
        }

        if (policies != '' && props.security.hasPermission(policies)) {
            return true;
        }

        if (props.work.settingsObj?.mainContractorCanFulfillSubs && props.security.isContractor()) {
            return true;
        } else if (selfContractor) {
            return true;
        }


        return false;
    }


    React.useEffect(() => {
        const ctx = {
            job: props.job,
            security: props.security,
            workId: props.work.id,
            jobHasContractor: props.selectedJobHasContractor,
            contractor: props.selectedContractor,
            selfContractorId: props.selectedJobHasContractor?.id,
            selfJobHasContractorId: props.selectedJobHasContractor?.id,
        };

        props.moduleManager
            .hasPermission('job.subcontractors.add', ctx)
            .then((canAddSubContractor: boolean) => {
                setCanAddSubContractorState(canAddSubContractor);
            });

    }, [selfContractor]);

    const canFinalize = props.security.hasPermission('job.finalize')
        || props.security.hasWorker(mainDepartment?.responsibleId)
        || props.security.hasPolicy('consejero')
        || props.security.isGestor();

    const initialize = async () => {
        const contractor = await props.getContractorFromAppUser(props.workId);
        setSelfContractor(contractor);

        const allRequirements: IRequirement[] = await props.getRequirements(props.workId, RequirementTargetType.JobHasDepartment, mainDepartment?.id,);

        allRequirements.forEach((c: IRequirement) => {
            c.status.forEach(s => {
                if (s.isCurrent) {
                    if (s.statusTypeId == RequirementStatusTypes.PENDING) {
                        anyPending.current = true;
                    }
                }
            });
        });
    }

    const setCurrentJobStatus = () => {
        const currentStatus = props.jobStatus.find(j => j.isCurrent);
        isNotified.current = currentStatus?.jobStatusTypeId == JobStatusType.INFORMATION_LOADED;
    };

    const canAuthorizeImpl = () => {
        if ((props.security.hasPolicy('consejero') && currentState?.jobStatusTypeId === JobStatusType.INFORMATION_LOADED)) {
            return true;
        } else if (props.security.isGestor()) {
            return true;
        }
        else if (props.security.hasPermission('job.authorize') && currentState?.jobStatusTypeId === JobStatusType.INFORMATION_LOADED) {
            return true;
        }
        return false;
    }

    const ctx = {
        job: props.job,
        currentStatus: currentState,
        security: props.security,
    };

    const canAuthorize = props.moduleManager.requirePermissionMemo(
        'job.authorize',
        ctx,
        canAuthorizeImpl(),
        [currentState]);

    const canRevoke = () => (currentState?.jobStatusTypeId === JobStatusType.AUTHORIZED &&
        props.security.hasPolicy('consejero')) ||
        props.security.isGestor() ||
        props.security.hasPermission('job.revoke');

    React.useEffect(() => {
        initialize();
    }, []);

    React.useEffect(() => {
        setCurrentJobStatus();
    }, [props.jobStatus]);

    const downloadJobInformation = () => {
        const url = `/api/${props.workId}/jobs/${props.job.id}/requirements/download`;
        window.location.href = url;
    }


    return <div id={'job-panel-actions'} className={'JobPanelActions'}>
        {dialogs.render('authorize', {
            title: t('job.authorize'), className: 'g pd', style: { minWidth: '30vw' }
        }, () =>
            <JobAuthorizeForm
                job={props.job}
                onCancel={dialogs.clear}
                onSuccess={reloadJob}
                workId={props.workId} />)}

        {dialogs.render('revoke', {
            title: t('job.revoke'), className: 'g pd', style: { minWidth: '30vw' }
        }, () =>
            <JobRevokeForm
                job={props.job}
                onCancel={dialogs.clear}
                onSuccess={reloadJob}
                workId={props.workId} />)}

        {dialogs.render('finalize', {
            title: t('job.finalize'), className: 'g pd', style: { minWidth: '30vw' }
        }, () =>
            <JobFinalizeForm
                job={props.job}
                onCancel={dialogs.clear}
                onSuccess={reloadJob}
                workId={props.workId} />)}

        {dialogs.render('reactivate', {
            title: t('job.reactivate'), className: 'g pd', style: { minWidth: '30vw' }
        }, () =>
            <JobReactivateForm
                job={props.job}
                onCancel={dialogs.clear}
                onSuccess={reloadJob}
                workId={props.workId} />)}

        <div id='job-actions' />

        <div className={'job-section'}>
            {props.security.isContractor() && (isNotified.current && !anyPending.current) &&
                <button className='download' onClick={downloadJobInformation}>
                    <i className='fas fa-download' />
                    <span className='e'>
                        {t('job.ibo.download')}
                    </span>
                </button>
            }
            {!props.security.isContractor() &&
                <Portal container={'#breadcrumb-right'}>
                    <JobStatus
                        job={props.job}
                        jobStatus={props.jobStatus}
                        workId={props.workId}
                        jobSettings={props.jobSettings} />
                </Portal>}
            <RequireRole oneOf={['worker', 'gestor']}>
                <TogglePanel className='right md mr-top'
                    bodyClassName={'sm pd'}
                    title={t('Actions')}>
                    <div id={'job-general-actions'}></div>
                    {(canAuthorize)
                        // #555: La carga de requisitos solo se puede hacer si el estado del trabajo es "Información Cargada"
                        // * Esta condición estaba provocando que se muestre el botón
                        // || props.security.hasPermission('job.authorize'))
                        &&
                        <button onClick={dialogs.showFromEvent('authorize')}>
                            <i className='fas fa-check-circle' />
                            <span className='e'>
                                {t('Authorize job')}
                            </span>
                        </button>}
                    {canRevoke() &&
                        <button onClick={dialogs.showFromEvent('revoke')}>
                            <i className='fas fa-times-circle' />
                            <span className='e'>
                                {t('Revoke job')}
                            </span>
                        </button>}
                    {currentState?.jobStatusTypeId != JobStatusType.FINALIZED &&
                        canFinalize &&
                        <button onClick={dialogs.showFromEvent('finalize')}>
                            <i className='fas fa-backspace' />
                            <span className='e'>
                                {t('Finalize job')}
                            </span>
                        </button>}
                    {currentState?.jobStatusTypeId == JobStatusType.FINALIZED &&
                        canFinalize &&
                        <button onClick={dialogs.showFromEvent('reactivate')}>
                            <i className='fas fa-redo' />
                            <span className='e'>
                                {t('job.reactivate')}
                            </span>
                        </button>}
                    <button onClick={() => goToJobInformationLoadedControl(props.workId, props.job.id)}>
                        <i className='fas fa-download' />
                        <span className='e'>
                            {t('job.downloads')}
                        </span>
                    </button>
                </TogglePanel>
            </RequireRole>

            <div className={'action'} onClick={() => redirectTo(`/work/${props.workId}/jobs/${props.job.id}/data`)}>
                <i className={'fas fa-table'} />
                {t('View job data')}
            </div>

            <div className={'action'} onClick={() => loadRequirements(props.workId, props.job.id)}>
                <i className="fas fa-folder" />
                {t('Documentation')}
            </div>
        </div>

        <div className={'section'}>
            <div className={'title'}>
                <i className="fas fa-id-card-alt" />
                {t('Contractors')}
            </div>
            <RequireRole oneOf={['gestor', 'worker']}>
                <div className={'body'}>
                    {/** CONTRATISTAS **/}
                    {true &&
                        <button className='o' onClick={() => {
                            if (!props.security.isContractor()) {
                                props.clearSelectedContractor();
                            }
                            redirectTo(`/work/${props.workId}/jobs/${props.job.id}/contractors`)
                        }}>
                            <i className='fas fa-list' />
                            <span className='e'>{t('View contractors')}</span>
                        </button>}
                    {(canSave('jobs.contractors.add')) &&
                        <button className='o' onClick={() => redirectTo(`/work/${props.workId}/jobs/${props.job.id}/add/contractor`)}>
                            <i className='fas fa-plus' />
                            <span className='e'>{t('Add contractor')}</span>
                        </button>}
                    {(canSave('job.contractors.invite') || props.security.isContractor()) &&
                        // <RequirePermission permission={'contractors.invite'} explicit={false} ctx={{job: props.job}}>
                        <button
                            className='o'
                            onClick={() => redirectTo(`/work/${props.workId}/jobs/${props.job.id}/contractor-invite`)}>
                            <i className='fas fa-envelope' />
                            <span className='e'>{t('Invite contractor')}</span>
                        </button>}
                    {/* </RequirePermission> */}
                    {canSave() &&
                        <RequireRole oneOf={['worker', 'gestor']}>
                            <button className='o' onClick={() => redirectTo(`/work/${props.workId}/jobs/${props.job.id}/contractor-requests`)}>
                                <i className='fas fa-mail-bulk' />
                                <span className='e'>{t('Contractor requests')}</span>
                            </button>
                        </RequireRole>}
                    {true &&
                        <button className='o' onClick={() => redirectTo(`/work/${props.workId}/jobs/${props.job.id}/sub-contractor-requests`)}>
                            <i className='fas fa-list-alt' />
                            <span className='e'>{t('job.contractors.requests')}</span>
                        </button>}
                    {(canSave() || props.security.isContractor()) &&

                            <button className='o' onClick={() => goToAddSubContractor()}>
                                <i className='fas fa-plus' />
                                <span className='e'>{t('job.contractors.add-subcontractor')}</span>
                            </button>
                        }
                </div>
            </RequireRole>
            <RequireRole oneOf={['admin', 'contractor']}>
                <div className={'body'}>
                    {/** CONTRATISTAS **/}
                    {true &&
                        <button className='o' onClick={() => redirectTo(`/work/${props.workId}/jobs/${props.job.id}/contractors`)}>
                            <i className='fas fa-list' />
                            <span className='e'>{t('View contractors')}</span>
                        </button>}
                    {(((canSave() || props.security.isContractor()) && canAddSubContractorState)) &&
                        <RequireRole role="contractor">
                            <button className='o' onClick={() => goToAddSubContractor()}>
                                <i className='fas fa-plus' />
                                <span className='e'>{t(`job.contractors.add-subcontractor`)}</span>
                            </button>
                        </RequireRole>}
                    {((canSave('job.contractors.invite') || props.security.isContractor()) && canAddSubContractorState) &&
                        <button
                            className='o'
                            onClick={() => redirectTo(`/work/${props.workId}/jobs/${props.job.id}/contractor-invite`)}>
                            <i className='fas fa-envelope' />
                            <span className='e'>{t('Invite contractor')}</span>
                        </button>}
                    {true &&
                        <button className='o' onClick={() => redirectTo(`/work/${props.workId}/jobs/${props.job.id}/sub-contractor-requests`)}>
                            <i className='fas fa-list-alt' />
                            <span className='e'>{t('job.contractors.requests')}</span>
                        </button>}
                </div>
            </RequireRole>
        </div>

        {props.selectedJobHasContractor != undefined &&
            <div className='section-subcontext'>
                <strong>{props.selectedJobHasContractor?.contractor?.name}</strong>
            </div>}

        {/**
         * WORKERS
         * **/}
        {props.selectedJobHasContractor != undefined &&
            <div className={'section'}>
                <div className={'title'}>
                    <i className="fas fa-user-friends" />
                    {t('Workers')}
                </div>
                <div className={'body'} id='job-actions-workers'>
                    <button className={'g'} onClick={() => redirectTo(`/work/${props.workId}/jobs/${props.job.id}/workers?jobContractor=${props.selectedJobHasContractor?.id}`)}>
                        <i className='fas fa-list' />
                        <span className='e'>{t('View workers')}</span>
                    </button>
                    {(canSave('jobHasWorker.add') || (props.work.settingsObj?.mainContractorCanFulfillSubs && props.security.isContractor())) &&
                        <button className={'g'} onClick={() => redirectTo(`/work/${props.workId}/jobs/${props.job.id}/workers/add?jobContractor=${props.selectedJobHasContractor?.id}`)}>
                            <i className='fas fa-plus' />
                            <span className='e'>{t('Add worker')}</span>
                        </button>}
                </div>
            </div>}
        {props.selectedJobHasContractor == undefined &&
            <div className={'section'}>
                <div className={'title'}>
                    <i className="fas fa-user-friends" />
                    {t('Workers')}
                </div>
                <div className={'body'} id='job-actions-workers'>
                    <button className={'g'} onClick={() => redirectTo(`/work/${props.workId}/jobs/${props.job.id}/workers`)}>
                        <i className='fas fa-list' />
                        <span className='e'>{t('View workers')}</span>
                    </button>
                    {((canSave('jobHasWorker.add') && !props.security.isContractor()) || props.security.isContractor()) &&
                        <button className={'g'} onClick={() => redirectTo(`/work/${props.workId}/jobs/${props.job.id}/workers/add`)}>
                            <i className='fas fa-plus' />
                            <span className='e'>{t('Add worker')}</span>
                        </button>}
                </div>
            </div>}

        {/** MAQUINARIA **/}
        {props.selectedJobHasContractor != undefined &&
            <div className={'section'}>
                <div className={'title'}>
                    <i className="fas fa-truck-loading" />
                    {t('Machineries')}
                </div>
                <div className={'body'}>
                    <button className={'b'} onClick={() => redirectTo(`/work/${props.workId}/jobs/${props.job.id}/machineries?jobContractor=${props.selectedJobHasContractor?.id}`)}>
                        <i className='fas fa-list' />
                        <span className='e'>{t('View machineries')}</span>
                    </button>
                    {(canSave('jobs.machineries.add') || (props.security.isContractor() && props.work.settingsObj?.mainContractorCanFulfillSubs == true)) &&
                        <button className={'b'} onClick={() => redirectTo(`/work/${props.workId}/jobs/${props.job.id}/machineries/add?jobContractor=${props.selectedJobHasContractor?.id}`)}>
                            <i className='fas fa-plus' />
                            <span className='e'>{t('Add machinery')}</span>
                        </button>}
                </div>
            </div>}
        {props.selectedJobHasContractor == undefined &&
            <div className={'section'}>
                <div className={'title'}>
                    <i className="fas fa-truck-loading" />
                    {t('Machineries')}
                </div>
                <div className={'body'}>
                    <button className={'b'} onClick={() => redirectTo(`/work/${props.workId}/jobs/${props.job.id}/machineries`)}>
                        <i className='fas fa-list' />
                        <span className='e'>{t('View machineries')}</span>
                    </button>
                    {/* {(canSave('jobs.machineries.new.add') && !props.security.isContractor()) && */}
                    {/* <RequirePermission explicit={false} permission={'jobs.machineries.new.add'}>
                            <button className={'b'} onClick={() => redirectTo(`/work/${props.workId}/jobs/${props.job.id}/machineries/create`)}>
                                <i className='fas fa-plus' />
                                <span className='e'>{t('Add new machine')}</span>
                            </button>
                        </RequirePermission> */}
                    {/* } */}
                    {canSave('jobs.machineries.add') &&
                        <button className={'b'} onClick={() => redirectTo(`/work/${props.workId}/jobs/${props.job.id}/machineries/add`)}>
                            <i className='fas fa-plus' />
                            <span className='e'>{t('Add machinery')}</span>
                        </button>}
                </div>
            </div>}

        {/*{props.children}*/}

    </div>
}

export function JobPanelActions(props: IProps) {

    const ctx = {
        JobAuthorizeForm: JobAuthorizeForm,
        JobRevokeForm: JobRevokeForm,
        JobFinalizeForm: JobFinalizeForm,
        JobReactivateForm: JobReactivateForm,
        JobStatus: JobStatus,
        goToJobAddContractor: goToJobAddContractor,
        goToJobInformationLoadedControl: goToJobInformationLoadedControl,
        getJobHasContractorFromAppUser: getJobHasContractorFromAppUser,
        getJobHasContractorFromId: getJobHasContractorFromId,
        getRequirements: props.getRequirements,
        RequirePermission: RequirePermission,
        RequireRole: RequireRole,
        TogglePanel: TogglePanel,
        useDialogs: useDialogs,
        useLoading: useLoading,
        useRemoteData: useRemoteData,
        Portal: Portal,
        RequirementStatusTypes: RequirementStatusTypes,
        RequirementTargetType: RequirementTargetType,
    }

    const res = props
        .moduleManager
        .renderComponent<IProps>('JobPanelActions', { ...props, ctx }, JobPanelActionsImpl);



    return res;
}
