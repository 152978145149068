import * as React from 'react';

import { Card, Chip, G, useDialogs, useForm, useLoading, useToast, useTranslation, ValidationBuilder } from '@components';
import {
    IActivityType, IContractorInvitation, ICountry, ILegalForm,
    PropertyGroupObjectType, IPropertyGroupType, IWork, PropertyTypes, IPropertyGroup, IContractorInvitationRegisterData
} from '@models';
import { delay, redirectTo } from '@utils';
import { CountryRegionSelect } from '@components/common/CountryRegionSelect';
import { DynamicProperties } from '@components/user/DynamicProperties';
import './RegisterContractor.scss';

export interface IProps {
    getContractorInvitation: Function;
    getWorkConstants: Function;
    registerContractorInvitation: Function;
    token: string;
    onSuccess?: Function;
    propertyGroupTypes?: IPropertyGroupType[],
    getWork: Function;
}


function RegisterContractorForm(props: IProps) {
    const { t } = useTranslation();
    const toast = useToast();
    const loading = useLoading(true);
    const [invitation, setInvitation] = React.useState<IContractorInvitation | undefined>();
    const [activityTypes, setActivityTypes] = React.useState<IActivityType[]>([]);
    const [legalForms, setLegalForms] = React.useState<ILegalForm[]>([]);
    const [countries, setCountries] = React.useState<ICountry[]>([]);
    const [legalFormMandatory, setLegalFormMandatory] = React.useState<boolean>(false);
    const [hiddenFields, setHiddenFields] = React.useState<string[]>([]);
    const [selectedLegalForm, setSelectedLegalForm] = React.useState<ILegalForm>();
    const propertiesInvalid = React.useRef<boolean>();
    const [isActive, setIsActive] = React.useState<boolean>(false);
    const dialogs = useDialogs();

    React.useEffect(() => {
        if (invitation) {
            const updateValues: any = {
                workId: invitation.workId,
                email: invitation.email,
                invitationId: invitation.id,
                jobId: invitation.jobId,
                parentId: invitation.parentJobHasContractorId,
                name: invitation.name,
                shipmentNumber: invitation.shipmentNumber,
                jobsDescription: invitation.jobsDescription,
                startDate: invitation.startDate,
            };
            form.setFieldValues(updateValues);
        }
    }, [invitation, activityTypes, legalForms, countries]);

    const createValidation = (workId?: number) =>
        ValidationBuilder
            .new()
            .notEmpty('name', 'Contractor name is required')
            .notEmpty('code', 'Code is required')
            .minLength('code', 4, 'Code must be at least 4 characters long')
            .notEmptyIf(legalFormMandatory, 'legalFormId', 'Legal form is required')
            .notEmpty('address', 'Address is required')
            .notEmpty('postalCode', 'Postal Code is required')
            .notEmpty('countryId', 'Country is required')
            .notEmpty('city', 'City is required')
            .notEmpty('userName', 'Username is required')
            .notEmpty('password', 'Password is required')
            .notEmpty('contactName', 'Name is required')
            .notEmpty('contactSurname', 'Surname is required')
            .notEmpty('contactPhone', 'Phone is required')
            .notEmptyIf(workId == 21, 'contactEmail', 'Contact email is required')
            .notEmptyIf(workId != 21, 'otherActivityType', 'Activity type is required')
            .email('contactEmail',)
            .notEmpty('email', 'Email is required')
            .notEmpty('passwordConfirmation', 'Password confirmation')
            .equalTo('passwordConfirmation', 'password', 'Passwords do not match')
            .lift();

    const form = useForm({
        initialValues: {
            code: '',
            name: invitation?.name ?? '',
            workId: invitation?.workId!,
            remarks: '',
            legalFormId: 0,
            activityTypeId: undefined,
            address: '',
            postalCode: '',
            countryId: undefined,
            regionId: undefined,
            countyId: undefined,

            contactName: '',
            contactSurname: '',
            contactPhone: '',
            contactEmail: '',

            invitationId: invitation?.id,
            jobId: invitation?.jobId,
            email: invitation?.email!,
            password: '',
            passwordConfirmation: '',
            properties: [{
                "value": "{}",
                "valueObj": {}
            }],

            providerCode: '',
            shipmentNumber: invitation?.shipmentNumber,
            jobsDescription: invitation?.jobsDescription,
            startDate: invitation?.startDate,
        },
        validate: createValidation(invitation?.workId),
        validateOnMount: true,
    });

    const initialize = loading.wrap(async (token: string) => {
        const data = await props.getContractorInvitation(token);
        setInvitation(data);

        if (data.isActive == false) {
            setIsActive(false);
            dialogs.show('contractor-invitation-expired');
            return;
        }

        setIsActive(true);

        const constants = await props.getWorkConstants(data.workId);

        if (constants.workSettings != undefined && constants.workSettings.contractorHiddenFields != undefined) {
            setLegalFormMandatory(!constants.workSettings.contractorHiddenFields.includes('legalFormId'));
        }

        if (constants.workSettings != undefined && constants.workSettings.contractorHiddenFields != undefined) {
            const hiddenFields = constants.workSettings.contractorHiddenFields;
            setHiddenFields(hiddenFields);
        }

        setActivityTypes(constants.activityTypes);
        setLegalForms(constants.legalForms);
        if (data.workId != 21) {
            for (let c in constants.countries) {
                if (constants.countries[c].regions.length > 0) {
                    constants.countries[c].regions = [];
                }
            }
        }
        setCountries(constants.countries);
    });

    const showField = (field: string) => {
        return !hiddenFields.includes(field);
    }

    const calculateProperties = () => {
        const values: IPropertyGroup[] = form.values.properties;
        let newValues: IPropertyGroup[] = [];
        if (selectedLegalForm?.hideContractorProperties) {
            values.map((p: IPropertyGroup) => {
                if (invitation?.propertyGroupTypes != undefined && invitation.propertyGroupTypes.find(g => g.id == p.propertyGroupTypeId)?.hidable) {
                    newValues = values.filter(v => v.propertyGroupTypeId == undefined && v.propertyGroupTypeId != p.propertyGroupTypeId && v.value != "{}");
                }
            });
            return newValues;
        }
        newValues = values.filter(v => v.propertyGroupTypeId != undefined && v.value != "{}")

        return newValues;
    }

    const doSave = loading.wrap(async (..._: any) => {
        const contractorData: IContractorInvitationRegisterData = {
            activityTypeId: form.values.activityTypeId,
            otherActivityType: form.values.otherActivityType,
            code: form.values.code,
            email: form.values.email,
            legalFormId: form.values.legalFormId,
            name: form.values.name,
            password: form.values.password,
            properties: calculateProperties(),
            workId: invitation?.workId!,
            address: form.values.address,
            contactEmail: form.values.contactEmail,
            contactName: form.values.contactName,
            contactPhone: form.values.contactPhone,
            contactSurname: form.values.contactSurname,
            countryId: form.values.countryId,
            countyId: form.values.countyId,
            invitationId: invitation?.id,
            remarks: form.values.remarks,
            userName: form.values.userName,
            parentId: invitation?.parentJobHasContractorId,
            jobId: invitation?.jobId,
            postalCode: form.values.postalCode,
            city: form.values.city,
            jobsDescription: invitation?.jobsDescription,
            shipmentNumber: invitation?.shipmentNumber
        }

        const resp = await props.registerContractorInvitation(contractorData);

        if (resp.hasValue) {
            toast.show(t('Contractor registered successfully'));
            props.onSuccess && props.onSuccess(form.values);
        }
        else {
            toast.error(t('Error'), { detail: t(resp.error) });
        }
    });

    React.useEffect(() => {
        const lf = legalForms.find(l => l.id == form.values.legalFormId);
        setSelectedLegalForm(lf);
    }, [form.values.legalFormId])

    const calculateFormPropertiesAreValid = () => {

        const mandatoryPropertyIds: number[] = [];
        const mandatoryPropertyNames: string[] = [];

        //Si existen el formulario, evaluamos las propiedades dinámicas acordes a las existentes
        if (form.values.properties != undefined && form.values.properties.length > 0) {
            let hiddens: number = 0;
            if (invitation?.propertyGroupTypes != undefined && invitation.propertyGroupTypes.length > 0) {
                const boolTextNames: string[] = [];
                invitation.propertyGroupTypes.map(g => {
                    // ¿Está ocultada esta propiedad por la forma legal y la propiedad hidable del grupo de propiedades?
                    const isHidden = selectedLegalForm?.hideContractorProperties && g.hidable;
                    if (!isHidden) {
                        if (g.mandatory) {
                            // Si el grupo de propiedades no está oculto y es obligatorio -> cargamos su ID y los nombres de sus propiedades
                            mandatoryPropertyIds.push(g.id!);
                            if (g.properties != undefined && g.properties.length > 0) {
                                g.properties.map(p => {
                                    mandatoryPropertyNames.push(p.name);
                                    // Si alguna de las propiedades es de tipo BoolText, la almacenamos
                                    if (p.typeId == PropertyTypes.BOOLTEXT) {
                                        boolTextNames.push(p.name);
                                    }
                                })
                            }
                        }
                    }
                    else {
                        // Si está ocultada, ya sea por:
                        //      1- El grupo de propiedades no es ocultable
                        //      2- La forma legal no oculta las propiedades
                        // -> La contabilizamos
                        hiddens += 1;
                    }
                });
                if (mandatoryPropertyIds != undefined && mandatoryPropertyIds.length > 0) {
                    const properties: any[] = [];

                    // Evaluamos cuáles son las propiedades del formulario que son obligatorias
                    form.values.properties.map((p: any) => {
                        mandatoryPropertyIds.map(m => {
                            if (p.propertyGroupTypeId == m) {
                                properties.push(p);
                            }
                        })
                    });
                    if (properties != undefined && properties.length > 0) {
                        const values: boolean[] = []

                        properties.map(p => {
                            // Si cualquiera de estas propiedades tiene valor, lo evaluamos
                            if (p.valueObj != "{}") {
                                mandatoryPropertyNames.map(m => {
                                    // Evaluamos si los BoolTexts hallados tienen valor de texto
                                    if (boolTextNames.includes(m)) {
                                        boolTextNames.map(b => {
                                            if (b == m) {
                                                const valueText = p.valueObj[m + "_text"];
                                                if (valueText == "" || valueText == undefined) {
                                                    values.push(false);
                                                }
                                                else {
                                                    values.push(true);
                                                }
                                            }
                                        })
                                    }
                                    // Obtenemos su valor teniendo en cuenta que undefined es false
                                    else {
                                        const value = p.valueObj[m];
                                        if (value != undefined) {
                                            values.push(value);
                                        }
                                        else {
                                            values.push(false);
                                        }
                                    }
                                })
                            }
                            else {
                                values.push(false);
                            }
                        });
                        // Si existe algún true en el array, entonces el grupo de propiedades es válido
                        if (values.some(v => v)) {
                            propertiesInvalid.current = false;
                        }
                        else {
                            if (hiddens < invitation.propertyGroupTypes.length) {
                                propertiesInvalid.current = true;
                            }
                        }
                    }
                }
                if (hiddens == invitation.propertyGroupTypes.filter(g => g.mandatory).length) {
                    propertiesInvalid.current = false;
                }
            }
        }
    }

    React.useEffect(() => {
        calculateFormPropertiesAreValid();
    }, [form.values.properties, selectedLegalForm])

    React.useEffect(() => {
        initialize(props.token);
    }, []);

    return <div className='c lg pd center h100p'>
        {toast.render()}

        {dialogs.render('contractor-invitation-expired', {
            modal: true,
            showCloseFooter: false,
            closable: false,
            draggable: false,
            title: t('Inactive invitation'),
        }, () =>
                <div style={{minHeight: '100px', textAlign: 'center'}}>
                    <div style={{paddingTop: '4%'}}>{t('contact.someone')}</div>
                </div>
        )}

        {invitation &&
            <div className='form-1 h100p'>
                <div className='rounded green box r'>
                    <i className='pi pi-user-edit' />
                    <span className='e'>{t('contractor.register.message')}</span>
                    <i className='pi' />

                    {loading.renderBox()}
                </div>
                <div className='r g-20'>
                    <div className='c g-20 l200'>
                        <h3 className='box-title'>{t('Contractor data')}</h3>
                        <G label={t('Contractor.Name')}>
                            {form.input('name')}
                        </G>
                        <G label={t('invitation.code')}>
                            {form.input('code')}
                        </G>
                        {showField('activityTypeId') && <G label={t('Activity type')}>
                            {form.select('activityTypeId', {
                                options: activityTypes,
                            })}
                        </G>}
                        {legalFormMandatory &&
                            <G label={t('Legal form')}>
                                {form.select('legalFormId', {
                                    options: legalForms.sort((a, b) => a.name.localeCompare(b.name)),
                                    filter: true,
                                })}
                            </G>}
                        {invitation.workId != 21 &&
                            <G label={t('Provider code')}>
                                {form.input('providerCode')}
                            </G>}
                        {invitation.workId != 21 &&
                            <G label={t('Activity type')}>
                                {form.input('otherActivityType')}
                            </G>}
                        <G label={t('Address')}>
                            {form.input('address')}
                        </G>
                        <G label={t('Postal code')}>
                            {form.input('postalCode')}
                        </G>
                        <G label={invitation.workId == 21 ? t('Country and region') : t('Country')}>
                            <div className='c'>
                                <CountryRegionSelect
                                    className='e'
                                    countries={countries}
                                    data={form.values}
                                    onChange={(d: any) => {
                                        form.setFieldValue('countryId', d.countryId);
                                        if (d.regionId) {
                                            form.setFieldValue('regionId', d.regionId);
                                        }
                                        if (d.countyId) {
                                            form.setFieldValue('countyId', d.countyId);
                                        }
                                    }} />
                                {form.getFormErrorMessage('countryId')}
                            </div>
                        </G>
                        <G label={t(invitation.workId == 21 ? 'City' : 'register.city')}>
                            {form.input('city')}
                        </G>
                        <G label={t('Remarks')}>
                            {form.textarea('remarks')}
                        </G>

                        {invitation.workId == 21 && <> <div id={'embed-properties'} className='EmbedProperties height' />
                            <DynamicProperties
                                object={form.values}
                                labelClass={'label-pec'}
                                hideContractorProperties={selectedLegalForm?.hideContractorProperties}
                                objectType={PropertyGroupObjectType.JobHasContractor}
                                propertyGroupTypes={invitation.propertyGroupTypes ?? []}
                                onChange={properties => form.setFieldValue('properties', properties)} />
                            {propertiesInvalid.current && <small className='p-error right-align'>{t('dynamic.properties.error')}</small>}
                        </>}

                    </div>
                    <div className='c g-20 l200'>
                        <h3 className='box-title'>{t('Contact data')}</h3>
                        <G label={t('Name')}>
                            {form.input('contactName')}
                        </G>
                        <G label={t('Surname')}>
                            {form.input('contactSurname')}
                        </G>
                        <G label={t('Phone')}>
                            {form.input('contactPhone')}
                        </G>
                        {invitation.workId == 21 && <G label={t('E-Mail')}>
                            {form.input('contactEmail')}
                        </G>}
                    </div>
                    <div className='c g-20 l200'>
                        <h3 className='box-title'>{t('Access data') + ' ' + '\(' + t('User') + '\)'}</h3>
                        <G label={t('Username')}>
                            {/* {form.input('username', {defaultValue: invitation.email})} */}
                            {form.input('userName')}
                        </G>
                        <G label={t('Email')}>
                            {form.input('email', { type: 'email' })}
                        </G>
                        <G label={t('Password')}>
                            {form.input('password', { type: 'password' })}
                        </G>
                        <G label={t('Password confirmation')}>
                            {form.input('passwordConfirmation', { type: 'password' })}
                        </G>
                    </div>
                    <div className='c g-20'>
                        {invitation.workId != 21 &&
                            <h3 className='box-title'>{t('Other data')}</h3>}
                        {invitation.workId != 21 && <> <div id={'embed-properties'} className='EmbedProperties reverse' />
                            <DynamicProperties
                                object={form.values}
                                hideContractorProperties={selectedLegalForm?.hideContractorProperties}
                                objectType={PropertyGroupObjectType.JobHasContractor}
                                propertyGroupTypes={invitation.propertyGroupTypes ?? []}
                                onChange={properties => form.setFieldValue('properties', properties)} />
                            {propertiesInvalid.current && <small className='p-error right-align'>{t('dynamic.properties.error')}</small>}
                        </>}
                    </div>
                </div>
                {form.errorBox()}
                <div className='e l200' />
                <div className='r l200'>
                    <div className='e l200' />
                    <button
                        className='primary'
                        disabled={form.isInvalid() || propertiesInvalid.current || !isActive}
                        onClick={doSave}>
                        {t('Create')}
                    </button>
                </div>
            </div>}
    </div>;
}

function RegisterContractorSuccess({ data }: { data: IContractorInvitation }) {
    const { t } = useTranslation();

    return <div className='md lg pd center'>
        <div className='c md lg pd'>
            <Card>
                <div className='c g-50'>
                    <div className='r g-50 center'>
                        <span className='e' />
                        {t('User created successfully')}
                        <Chip label={data.email} className={'info'} />
                        <span className='e' />
                    </div>

                    <div className='r'>
                        <span className='e' />
                        <button className='primary' onClick={() => redirectTo('/')}>
                            {t('Init session')}
                        </button>
                        <span className='e' />
                    </div>
                </div>
            </Card>
        </div>
    </div>
}

enum State {
    INIT = 0,
    SUCCESS = 1,
}

export function RegisterContractor(props: IProps) {
    const [state, setState] = React.useState<State>(State.INIT);
    const [data, setData] = React.useState<IContractorInvitation | undefined>();

    const onSuccess = async (data: IContractorInvitation) => {
        setData(data);
        setState(State.SUCCESS);
        await delay(2000);
        redirectTo('/');
    }

    if (state == State.INIT) {
        return <RegisterContractorForm
            {...props}
            onSuccess={onSuccess} />;
    }
    else if (state == State.SUCCESS && data) {
        return <RegisterContractorSuccess data={data} />
    }
    else {
        return null;
    }
}
