import * as React from 'react';

import {
    Accordion, AccordionTab, BreadcrumbItem, Chip, F,
    useDataTable, useDebounce, useForm, useLoading, useSearch, useStringState, useTranslation,
    useWorkFormSettings,
    ValidationBuilder
} from '@components';
import {
    IJob, IWork, IContractor, IPropertyGroup, PropertyGroupObjectType, IPropertyGroupType,
    IJobHasContractor,
    ISecurity,
    ISubContractorRequest, ISubMainContractorRequest
} from '@models';
import { DynamicProperties } from '../user/DynamicProperties';
import dateUtils from '@utils/date-utils';
import { ModuleManager } from '@modules';

export interface IProps {
    job: IJob;
    modules: ModuleManager;
    parentContractor: IJobHasContractor;
    propertyGroupTypes: IPropertyGroupType[];
    onCancel: Function;
    onSuccess: Function;
    saveJobHasContractor: Function;
    saveSubContractorRequest: Function;
    saveSubMainContractorRequest: Function;
    searchContractors: Function;
    security: ISecurity;
    work: IWork;
    getJobContractors: Function;
    getContractorFromAppUser: Function;
    main: boolean;
}

export const validateContractor = (data: Partial<IJobHasContractor>, work: IWork) => {
    return ValidationBuilder.create(data)
        .notEmptyIf(work.contractorSettings?.jhcStartDateMandatory == true,'startDate', 'Date is required')
        .notEmptyIf(work.contractorSettings?.jhcDescriptionMandatory == true,'jobsDescription')
        .build();
}

const isValid = (data: Partial<IJobHasContractor>, work: IWork) => {
    return Object.keys(validateContractor(data, work)).length == 0;
}

export function JobAddContractor(props: IProps) {
    const { t } = useTranslation();
    const currentSearchTerm = useStringState();
    const loading = useLoading(false);

    const [activeIndex, setActiveIndex] = React.useState<number>(0);
    const [contractors, setContractors] = React.useState<IContractor[]>([]);
    const [selectedContractor, setSelectedContractor] = React.useState<IContractor|undefined>();
    const debouncedSearchTerm = useDebounce(currentSearchTerm.value, 500);


    const doSearch = loading.wrap(async (term: string|undefined) => {
        const selfContractors = await props.getContractorFromAppUser(props.work.id);
        let data = await props.searchContractors(props.work.id, term ?? '');
        const jobContractors: IJobHasContractor[] = await props.getJobContractors(props.work.id, props.job.id);

        const filteredData = data.filter((d : any) => {
            const foundContractor = jobContractors.find((jc: IJobHasContractor) => jc.contractorId == d.id);
            if (!foundContractor) return true; // Mantener en data si no se encuentra el contratista

            const isParentUndefined = props.parentContractor == undefined && foundContractor.parentId == undefined;
            const isParentMatch = props.parentContractor?.id == foundContractor?.parentId;

            return !(isParentUndefined || isParentMatch); // Eliminar de data si alguna condición se cumple
        });

        data = filteredData;


        const contractorsToExclude = Array.isArray(selfContractors)
            ? selfContractors.map((c: any) => c.id)
            : selfContractors ? [selfContractors.id] : [];
        data = data
            .filter((d: IContractor) => !contractorsToExclude.includes(d.id))
            .sort((a: IContractor, b: IContractor) => a.name.localeCompare(b.name));

        const filterContractors : any = props.modules
        .filterDependency('job.add_main_contractor', {
            data, props, jobContractors
        });

        if(filterContractors() == true){
            setContractors(data);
        }else{
            setContractors(filterContractors)
        }

    });

    React.useEffect(() => {
        doSearch(debouncedSearchTerm);
    }, [debouncedSearchTerm]);

    React.useEffect(() => {
        setActiveIndex(selectedContractor ? 1 : 0);
    }, [selectedContractor]);


    const saveSubMainContractorRequest = loading.wrap(async (data: Partial<IJobHasContractor>) => {
        const inputData: ISubMainContractorRequest = {
            workId: props.work.id,
            jobId: props.job?.id,
            targetContractorId: data.contractorId!,
            jobsDescription: data.jobsDescription!,
            shipmentNumber: data.shipmentNumber! || '',
            startDate: data.startDate!,
        };
        const resp = await props.saveSubMainContractorRequest(props.work.id, inputData);

        if (resp.hasValue) {
            props.onSuccess('Contractor requested successfully');
            return { ok: true };
        }
        else {
            return { ok: false, message: t(resp.error) };
        }
    });



    //TODO: CHECK SAVE SUBCONTRACTOR ?

    const saveSubContractorRequest = loading.wrap(async (data: Partial<IJobHasContractor>) => {
        const inputData: ISubContractorRequest = {
            workId: props.work.id,
            jobId: props.job?.id,
            jobsDescription: data.jobsDescription!,
            shipmentNumber: data.shipmentNumber!,
            jobHasContractorId: data.parentId!,
            sourceContractorId: props.parentContractor?.id,
            targetContractorId: data.contractorId!,
            properties: data.properties,
        };
        const resp = await props.saveSubContractorRequest(props.work.id, inputData);

        if (resp.hasValue) {
            props.onSuccess('Contractor requested successfully');
            return { ok: true };
        }
        else {
            return { ok: false, message: t(resp.error) };
        }
    });

    const settings = useWorkFormSettings({work: props.work, scope: 'contractor'});

    const saveNewContractor = loading.wrap(async (data: Partial<IJobHasContractor>) => {
        if (data.startDate == null)
        {
            data.startDate = new Date;
        }
        const resp = await props.saveJobHasContractor(props.work.id, data);

        if (resp.hasValue) {
            props.onSuccess('Contractor requested successfully');
            return { ok: true };
        }
        else {
            return { ok: false, message: t(resp.error) };
        }
    });

    const form = useForm<Partial<IJobHasContractor>>({
        initialValues: {
            jobId: props.job.id,
            parentId: props.parentContractor?.id,
            properties: [],
        },
        validateOnMount: true,
        validate: (data: Partial<IJobHasContractor>) => {
            return validateContractor(data, props.work);
        },
        onSubmit: (data: Partial<IJobHasContractor>) => {

            if (props.main ===false) {

                return saveSubMainContractorRequest(data);
            }
            else if (props.security.isContractor()) {
                return saveSubContractorRequest(data);
            }
            else {
                return saveNewContractor(data);
            }
        },
    });

    const contractorsTable = useDataTable({
        columns: [
            'code',
            'name',
            ...props.modules.injectDataColumns('jobHasContractors.add.list', {workId: props.work.id}),
        ],
        actions: [
            {text: 'Select', headerStyle: {width: '116px'}, className: 'alt slim', onClick: setSelectedContractor},
        ],
        data: contractors,
        className: 'center-column'
    });

    const noShipmentNumber = settings.show('shipmentNumber') && props.parentContractor == undefined;

    return <>
        {!props.parentContractor &&
            <BreadcrumbItem
                text={t('Add contractor')} />}

        {props.parentContractor &&
            <BreadcrumbItem>
                {t('Add sub contractor')}
                <Chip className='sm mr-left primary' label={props.parentContractor.contractor.name} />
            </BreadcrumbItem>}

        <Accordion
            className='flat borderless'
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}>
            <AccordionTab header={t('Search contractor')}>
                <div className='c'>
                    <div className='r'>
                        <input
                            className='e'
                            type='search'
                            value={currentSearchTerm.value}
                            onChange={currentSearchTerm.set} />
                        {loading.render()}
                    </div>
                    {contractorsTable()}
                </div>
            </AccordionTab>
            <AccordionTab header={t('Contractor data')} disabled={!selectedContractor}>
                {selectedContractor &&
                    <form
                        className='sm pd c'
                        onSubmit={form.handleSubmit}>
                        <div className='r'>
                            <div className='c e form-2'>
                                <F label={t('Contractor to add')}>
                                    <span>
                                        {selectedContractor?.name}
                                        <Chip label={selectedContractor?.code} className='sm mr-left'/>
                                    </span>
                                </F>
                                <F label={t("Work's entry date")}>
                                    {form.input('startDate', { type: 'date'})}
                                </F>
                                <F label={t("Jobs into this work")}>
                                    {form.textarea('jobsDescription')}
                                </F>
                                {/** Solo mostramos el numero de pedido en los contratistas
                                 * principales
                                 */}
                                {noShipmentNumber &&
                                <F label={t("Shipment number")}>
                                    {form.textarea('shipmentNumber')}
                                </F>}
                            </div>

                            <div className='c sm pd e'>
                                <DynamicProperties
                                    propertyGroupTypes={props.propertyGroupTypes}
                                    object={form.values}
                                    objectType={PropertyGroupObjectType.JobHasContractor}
                                    onChange={(properties: IPropertyGroup[]) => form.setFieldValue('properties', properties)} />
                            </div>
                        </div>
                        <div className='e'></div>

                        <div className='errors-container'>
                            {form.errorBox()}
                        </div>
                        {(props.work.contractorSettings?.jhcStartDateMandatory == true) &&
                            <div>
                                {!(dateUtils.compareDates(dateUtils.parseDate(form.values.startDate), dateUtils.parseDate(props.job.startDate as any)) >= 0) &&
                                    <small className='p-error right-align'>
                                        {t('La fecha no puede ser anterior a la fecha de inicio de contrato')}
                                    </small> ||
                                !(dateUtils.dateISTodayOrFuture(dateUtils.parseDate(form.values.startDate))) &&
                                    <small className='p-error right-align'>
                                        {t('La fecha no puede ser anterior a la del día de hoy')}
                                    </small> ||
                                !(dateUtils.compareDates(dateUtils.parseDate(form.values.startDate), dateUtils.parseDate(props.job.endDate as any)) < 0) &&
                                    <small className='p-error right-align'>
                                        {t('La fecha no puede ser mayor a la fecha de fin de contrato')}
                                    </small>
                                }
                            </div>
                        }
                        <div className='cols-2 r'>
                            <span className='e'></span>
                            {loading.render()}
                            <div className='p-buttonset'>
                                <button
                                    onClick={() => props.onCancel()}>
                                    {t('Cancel')}
                                </button>
                                {props.work.contractorSettings?.jhcStartDateMandatory == true &&
                                    <button
                                        disabled={(!isValid(form.values, props.work) || loading.isLoading() ||
                                            !(dateUtils.compareDates(dateUtils.parseDate(form.values.startDate), dateUtils.parseDate(props.job.startDate as any)) >= 0) ||
                                            !(dateUtils.compareDates(dateUtils.parseDate(form.values.startDate), dateUtils.parseDate(props.job.endDate as any)) < 0) ||
                                            !(dateUtils.dateISTodayOrFuture(dateUtils.parseDate(form.values.startDate))))
                                        }
                                        type='submit'
                                        className='primary'>
                                        {t('Create')}
                                    </button>
                                }
                                {props.work.contractorSettings?.jhcStartDateMandatory == false &&
                                    <button
                                        type='submit'
                                        className='primary'>
                                        {t('Create')}
                                    </button>
                                }
                            </div>
                        </div>
                    </form>}
            </AccordionTab>
        </Accordion>
    </>;
}
