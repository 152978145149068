import * as React from 'react';

import { BreadcrumbItem, useDataTable, useRemoteData, useSearch, useTranslation } from '@components';
import { IContractorInvitation, ISecurity, IWork, ModuleManager } from '@models';
import dateUtils from '@utils/date-utils';

export interface IProps {
    getContractorInvitations: Function;
    security: ISecurity;
    work: IWork;
    moduleManager: ModuleManager;
}
export function ViewContractorInvitationsImpl(props: IProps) {
    const { t } = useTranslation();

    let data = useSearch<IContractorInvitation>({
        workId: props.work.id,
        search: 'contractors/invitations.list',
        filters: {
            workId: props.work.id
        },
    });

    const sortedData = data.value.length > 0 ? data.value.sort((a, b) => dateUtils.compareDates(dateUtils.fromUtc(b.dateTime!), dateUtils.fromUtc(a.dateTime!))) : [];
    const dataTable = useDataTable({
        data: sortedData,
        columns: [
            { title: t('creation.date'), field: 'dateTime', delegate: 'datetime', className: 'td-datetime', sortKey: 'dateTime' },
            { title: t('contractor.invitation.email'), field: 'email', sortKey: 'email' },
            {
                title: t('User'), render: (row: any) => {
                    return <span>{props.work.users.find(u => u.id == row.createdBy)?.userName}</span>
                }, sortKey: 'createdBy'
            },
            {
                title: t('Subject'), render: (row: any) => {
                    if (props.work.id != 23) {
                        return <span>{row.subject}</span>
                    }
                    else {
                        return <span>{t('ContractorInvitation.Azsa.message')}</span>
                    }
                }
            },
            { title: t('contractor.invitation.accepted'), field: 'accepted', delegate: 'boolean', className: 'td-md-icon' },
            { title: t('Empresa'), field: 'name', sortKey: 'name' }
        ],
        loading: data.loading,
    });

    return <div className='he c'>
        <BreadcrumbItem text={t('Invitaciones de contratistas')} />
        {dataTable()}
    </div>
}

export function ViewContractorInvitations(props: IProps) {

    const res = props
        .moduleManager
        .renderComponent<IProps>('ViewContractorInvitations', { ...props }, ViewContractorInvitationsImpl);

    return res;
}


