import * as React from 'react';
import { IReportDefinition, IReportOutput, ReportOutputType } from '@models/reports';
import { ISecurity } from '@models';
import { ReportOutputTableComponent } from './ReportOutputTableComponent';
import { ReportOutputBarChartComponent } from '@components/reports/ReportOutputBarChartComponent';

export interface IProps {
    filtersContext: any;
    filterParameters?: any;
    report: IReportDefinition;
    output: IReportOutput;
    security: ISecurity;
    workId: number;
    className?: string;
}

export function ReportOutputComponent(props: IProps) {

    if (props.output.outputType == ReportOutputType.Table) {
        return <ReportOutputTableComponent
            filtersContext={props.filtersContext}
            filterParameters={props.filterParameters}
            report={props.report}
            output={props.output}
            security={props.security}
            workId={props.workId} />
    }
    else if (props.output.outputType == ReportOutputType.BarChart) {
        return <ReportOutputBarChartComponent
            report={props.report}
            output={props.output}
            security={props.security}
            workId={props.workId} />
    }
    else {
        return <div></div>;
    }
}
